@use 'common'

.wrapper
	display: block
	position: relative
	aspect-ratio: 1208 / 549

.in
	position: relative
	color: #ffffff
	display: grid
	align-items: center
	justify-items: center
	gap: 1em
	padding: 2em
	min-height: 100%

	@media (min-width: common.$break38)
		grid-template-columns: 1fr 1fr

.content
	max-width: 26rem
	display: inline-flex
	flex-direction: column
	gap: 1em
	align-items: flex-start

.name
	margin: 0
	font-weight: 800
	font-size: 2.7em
	line-height: 1

.description
	font-size: 0.875em
	color: #c4c4c4

.backgroundImage
	position: absolute
	inset: 0
	border-radius: common.$border-radius
	overflow: hidden
	background-color: #523bff

.applicationImage
	width: 100%
	max-width: 26em
	margin: 0 auto
