@use 'common'

.wrapper
	//

.main
	padding: 8.125rem 0

	&.is_background_white, &.is_background_grey
		.title
			color: #000000

	&.is_background_blue, &.is_background_black
		.title
			color: #FFFFFF

.content
	color: inherit
	max-width: 46.875rem
	margin: 0 auto
	text-align: center
	margin-bottom: 5rem

.title
	font-weight: 700
	font-size: 2.8125rem
	line-height: 3.625rem
	margin: 0
	margin-bottom: 3.75rem
	color: inherit

	@media (min-width: common.$break24)
		font-size: 3rem

.subtitle
	margin-top: 0
	//

.logos
	//
