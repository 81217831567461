@use 'common'

$contentMaxWidth: 27.5rem

.wrapper
    padding-left: 0
    padding-right: 0

    @media (min-width: common.$break38)
        padding-left: var(--global-horizontal-spacing)
        padding-right: var(--global-horizontal-spacing)

        &.view_left
            .form
                &.is_alignment_nextToContent
                    // margin-left: auto

        &.view_right
            .content
                order: 1

            .form
                &.is_alignment_nextToContent
                    // margin-right: auto

            .waveShape
                transform: scaleX(-1)
                left: auto
                right: var(--AsideContent-waveShape-horizontalPosition)

    @media (min-width: common.$contentWidth-normal)

        &.view_left, &.view_right
            &.is_asideMediaOutOfLayout
                .main
                    gap: calc(#{common.$contentWidth-normal} / 2 - #{$contentMaxWidth} + var(--global-horizontal-spacing) / 2)

        &.view_left
            &.is_asideMediaOutOfLayout
                padding-right: 0

                .main
                    justify-content: flex-end

        &.view_right
            &.is_asideMediaOutOfLayout
                padding-left: 0

                .main
                    justify-content: flex-start

.main
    min-height: auto
    display: flex
    flex-direction: column-reverse
    gap: 0
    &.hasForm
        flex-direction: column

    @media (min-width: common.$break38)
        display: flex
        flex-direction: row
        justify-content: space-between
        gap: 1rem

        &.is_accordion
            padding-top: 0rem

        &.hasForm
            flex-direction: row

.content
    display: flex
    flex-direction: column
    align-items: flex-start

    padding: 4.5rem 3.5rem
    border-radius: 0
    background: #F5F5F5
    &.hasForm
        background: #0A0A0E
        padding: 0rem 0rem 0rem 2rem

    &.is_flexStart
        align-items: flex-start
        justify-content: flex-start
        padding: 1.5rem 1.5rem

    .homePageWithForm
        display: flex
        justify-content: center
        align-items: center
        height: 100%

    &.is_accordion
        align-items: flex-start
        justify-content: flex-start
        background: transparent
        padding: 0rem 1.5rem

        @media (min-width: common.$break48)
            padding: 0 0rem
            z-index: 1

    @media (min-width: common.$break38)
        min-width: calc(50% - 0.5rem)
        padding: 3.5rem 2.5rem
        border-radius: 56px

    @media (min-width: common.$break48)
        width: 50%
        padding: 5%
        border-radius: 56px

    @media (min-width: common.$break62)
        padding: 5%

    @media (min-width: common.$break81)
        width: 50%
        padding: 5rem 5rem
        border-radius: 56px

.title
    // +common.title
    font-family: common.$objectSansStack
    font-size: clamp(1.5rem, 4vw, 3.125rem)
    font-weight: 400
    line-height: 2rem
    letter-spacing: -0.03rem

    width: 100%

    margin: 0
    margin-bottom: 1.875rem

    br
        display: none

    &.hasForm
        font-size: 32px
        line-height: 40px

    &.fustatHere
        font-family: common.$fustat
        font-weight: 400

    &.is_accordion
        padding-left: 0

    @media (min-width: common.$break38)

        &.hasForm
            font-size: 50px
            line-height: 60px

    @media (min-width: common.$break62)
        line-height: 3.5rem
        letter-spacing: -0.125rem

        br
            display: block

        &.is_accordion
            padding-left: 3.5rem
            margin-top: 3rem

        &.hasForm
            font-size: 64px
            line-height: 64px

.description
    margin-bottom: 2.5rem

    font-size: 1.125rem
    font-style: normal
    font-weight: 400
    line-height: 1.625rem

    @media (min-width: common.$break38)
        max-width: 23.125em

.form
    --AsideContent-form-maxWidth: 20rem
    --AsideContent-form-padding: 1.25rem

    color: common.$textColor

    &.isInAsideContent
        color: white !important

    @media (min-width: common.$break62)
        --AsideContent-form-padding: 2.5rem

        max-width: var(--AsideContent-form-maxWidth)

    &.is_box
        padding: 0rem 2rem

        border-radius: common.$border-radius
        // box-shadow: 0 0 24px rgba(0, 0, 0, 0.15)

        width: 100%

        @media (min-width: common.$break38)
            max-width: calc(var(--AsideContent-form-maxWidth) + 2 * var(--AsideContent-form-padding))
            padding: 0rem 3rem

    &.is_alignment_default
        //

    &.is_alignment_nextToContent
        --AsideContent-form-maxWidth: 28.125rem

.medias
    flex: 1
    height: 100%
    display: flex
    flex-direction: column
    gap: 2.5rem
    position: relative

    &.hasForm
        margin-top: 0

    &.is_accordion
        background: transparent
        padding: 1.5rem

    @media (min-width: common.$break38)
        border-radius: 56px
        min-width: calc(50% - 0.5rem)
        height: 100%
        margin-top: unset
        position: sticky
        top: 4.6875rem

        &.is_accordion
            padding: 0
            position: relative
            top: 0rem

.waveShape
    --AsideContent-waveShape-horizontalPosition: -7.5rem
    --AsideContent-waveShape-bottomPosition: -5.625rem

    display: flex
    color: common.$blue
    position: absolute
    font-size: 16em
    left: var(--AsideContent-waveShape-horizontalPosition)
    bottom: var(--AsideContent-waveShape-bottomPosition)
    z-index: 0

    @media (min-width: common.$break38)
        --AsideContent-waveShape-horizontalPosition: -2.5rem
        --AsideContent-waveShape-bottomPosition: -6.25rem

    @media (min-width: common.$break48)
        font-size: 20em

    @media (min-width: common.$break62)
        --AsideContent-waveShape-horizontalPosition: -5.5rem
        --AsideContent-waveShape-bottomPosition: -5.75rem
        font-size: 25em

    @media (min-width: common.$break75)
        --AsideContent-waveShape-horizontalPosition: -22rem
        font-size: 30em

.iconWrapper
    padding-bottom: 1rem

.tileWrapper
    margin: 2rem 0
    width: 100%
    display: flex
    flex-direction: column
    gap: 1.125rem

.tileContainer
    width: 100%
    display: flex
    flex-direction: row
    gap: 0.5rem
    font-family: common.$sourceSans3
    font-size: 1.125rem
    font-style: normal
    font-weight: 400
    line-height: 1.625rem
