@use 'common'
@use 'sass:math'

.wrapper
	display: grid
	column-gap: 1.0625rem
	row-gap: 2.125rem

	@media (min-width: common.$break32)
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break48)
		grid-template-columns: 1fr 1fr 1fr

.column
	flex-basis: 25%
	display: flex
	flex-direction: column
	gap: 1rem
	&:nth-child(odd)
		margin-top: 3.125rem

.item
	&.is_mobileHidden
		@media not all and (min-width: common.$break32)
			display: none

.gridCard
	display: flex
	flex-direction: column
	align-items: stretch
	height: 100%
	gap: 1rem
	padding: 2.5rem 2rem 2.1875rem

.title
	font-weight: 700
	font-size: 1.625rem
	line-height: 1.23
	margin: 0

.form
	flex-grow: 1
	width: 100%
	display: flex
	flex-direction: column
	color: common.$textColor

.text
	font-weight: 400
	line-height: common.$line-height
