@use 'common'

.wrapper
	padding-top: 5rem
	// padding-bototm: 5rem
	.view_mobile
		@media (min-width: common.$break30)
			display: none

	.view_desktop

		@media not all and (min-width: common.$break30)
			display: none
