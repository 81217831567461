@use '../variables'
@use './headingsColor'

=background($theme, $isNotCustomBackground: false)
	&.is_background_blue
		background-color: variables.$blue
		color: #FFFFFF

	&.is_background_black
		background-color: var(--background-dark, #{variables.$dark})
		color: #FFFFFF

	&.is_background_white
		+headingsColor.headingsColor($theme, $isNotCustomBackground)

	&.is_background_grey
		+headingsColor.headingsColor('light', $isNotCustomBackground)

	&.is_background_grey
		background-color: #F4F4F4
	
	&.is_background_darkBlue
		color: #FFFFFF !important
		background-color: #031C2E
