@use 'common'

.wrapper
	//

.video
	position: relative
	aspect-ratio: 16 / 9
	border-radius: .75rem
	overflow: hidden

	&::before
		content: ''
		background-color: currentColor
		position: absolute
		inset: 0
		opacity: 0.2

.sourceContainer
	display: flex
	width: 100%
	justify-content: center

.videoSource
	font-size: 0.875rem
	font-weight: 400
	line-height: 1.375rem
	margin-top: 1rem
	@media (min-width: common.$break62)
		font-size: 1.125rem
		font-weight: 400
		line-height: 1.625rem
		margin-top: 1.5rem

.roundedStyles
	border-radius: 0
	overflow: hidden
	@media (min-width: common.$break62)
		border-radius: 2rem
