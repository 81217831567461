@use 'common'

$titleInlineSpacing: .5rem
$contentPadding: 2.125rem
$verticalSpacing: 30px

.wrapper
	position: relative
	width: 100%

	&::before
		content: ''
		position: absolute
		bottom: 100%
		right: 0
		left: 0
		height: $verticalSpacing
		z-index: 1

.titleWrapper
	font-size: 2rem
	line-height: 1
	font-weight: 400
	letter-spacing: common.$letter-spacing
	color: #AFAFAF
	margin-inline: -$titleInlineSpacing

	@media (min-width: common.$break38)
		font-size: 3rem

	@media (min-width: common.$break62)
		font-size: 4rem
		line-height: 4.375rem
		letter-spacing: -0.16rem

	&.is_active
		color: common.$blue

.title
	color: inherit
	padding-inline: $titleInlineSpacing
	transition: color .2s
	font-family: common.$objectSansStack

	&.is_active
		> span > svg > use
			opacity: 1

.title > span
	transform: none !important
	transform: none !important

.title > span > svg
	height: .7em

	@media (min-width: common.$break38)
		height: .85em

	@media (min-width: common.$break62)
		height: 1em

.title > span > svg > use
	transition: opacity .2s
	opacity: .5

.iconWrapper
	position: relative
	z-index: 0
	margin-inline: $titleInlineSpacing
	display: inline-flex

.icon
	transition: filter .2s

	&.is_hovered
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%, -50%)
		filter: brightness(0) saturate(100%) invert(18%) sepia(85%) saturate(4495%) hue-rotate(241deg) brightness(87%) contrast(130%) // generated by https://angel-rs.github.io/css-color-filter-generator/
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: .2s
		z-index: 0

.mediaContent
	margin-top: 1rem
	display: none
	width: 100%
	max-width: 58.75rem
	border-radius: 1.875rem
	background-color: #FFFFFF
	transition-property: visibility, opacity
	transition-duration: .2s
	z-index: 2
	box-shadow: 0 0 1.125rem 0.5rem rgb(0 0 0 / 15%)
	overflow: hidden
	flex-direction: column

.posterPreview
	padding: unset
	margin: unset
	border: unset
	border-radius: 1.875rem

	position: relative
	width: 100%
	z-index: 0
	aspect-ratio: calc(var(--RevealableMediaContentItem-poster-width) / var(--RevealableMediaContentItem-poster-height))
	flex-shrink: 0
	display: flex
	align-items: center
	background-color: #000000
	cursor: pointer

	img
		border-radius: 1.875rem

	&.is_desktop
		@media not all and (min-width: common.$break32)
			display: none

	&.is_mobile
		@media (min-width: common.$break32)
			display: none

	&In
		width: 100%
		object-fit: contain

	&::after
		content: ''
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: .4s
		background-color: rgba(#000000, .2)
		position: absolute
		inset: 0
		z-index: 0
		border-radius: 1.875rem

	&:hover, &:focus-visible
		&::after
			visibility: initial
			opacity: 1

.playButton
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	display: flex
	font-size: 2.52975rem
	color: #FFFFFF
	z-index: 1

.content
	text-align: initial
	padding: $contentPadding
	color: #000000
	width: 100%

	display: flex
	flex-direction: column
	justify-content: space-between
	align-items: flex-start

.text
	width: 100%
	font-size: 1.25em
	line-height: 1.4583
	letter-spacing: common.$letter-spacing
	margin: unset

.footer
	font-family: common.$sourceSans3Stack
	font-size: 1rem
	line-height: 1.625rem

	br
		display: block !important

	b
		font-weight: 500

		margin-top: 1.5625rem
