@use 'common'
@use 'sass:math'

$-transition-duration: 0.3s

.wrapper
	position: relative
	line-height: 1.74
	font-size: 1.2em

	&::before
		content: ''
		position: absolute
		inset: -1em -1.7em
		border: 0.125rem solid transparent
		transition-property: border-color
		transition-duration: $-transition-duration

	&.is_transitioning::before
		transition-duration: math.div($-transition-duration, 3)
		border-color: common.$blue

.in
	overflow: hidden
	height: var(--GemmaContentText-height)
	transition-property: height
	transition-duration: $-transition-duration

	&::before
		// onTransitionEnd hack when height doesn't change
		content: ''
		transition-property: visibility
		transition-duration: $-transition-duration

		.is_transitioning &
			visibility: hidden

.content
	display: flex
	align-items: flex-start
	width: 100%
	flex-shrink: 0
	font-weight: 400
	--GemmaContentText-fontFamily: serif
	font-family: var(--GemmaContentText-fontFamily)

	.is_heading &
		--size-factor: 1
		--GemmaContentText-fontFamily: sans-serif
		font-size: calc(var(--size-factor) * 2em)
		line-height: 1.145

	& + &
		margin-left: -100%
		opacity: 0

	// Preloads special font
	&::before
		content: 'x'
		visibility: hidden
		position: absolute
		font-size: 0 !important
		font-family: var(--GemmaContentText-fontFamily)

	&.is_specialFont
		--GemmaContentText-fontFamily: #{common.$pangram}

	.is_heading &.is_specialFont
		--GemmaContentText-fontFamily: #{common.$ppWoodland}
		letter-spacing: -0.04em
		--size-factor: 1.05
