@use 'common'

.timePeriod
	display: flex
	flex-direction: column
	padding: 2rem 0 1.75rem
	border-top: 1px solid #000000
	@media (min-width: common.$break48)
		flex-direction: row
		align-items: center

.title
	font-weight: 700
	font-size: 2.75rem
	line-height: 1.046
	margin: 0
	width: 20%

.text
	margin: 1rem 0 2rem
	font-size: 1.25rem
	line-height: 2rem
	width: 80%
	@media (min-width: common.$break48)
		margin: 0 0 0 1rem
