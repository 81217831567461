@use 'common'

$-inner-gap: 0.4rem
$-border-radius: 0.4rem
$-animation-duration: 0.3s

.wrapper
	display: grid
	grid-template-rows: 0fr
	transition-property: grid-template-rows
	transition-duration: $-animation-duration

	// spacial case: too big space when text is wrapping
	&:nth-child(11) .text
		max-width: 80%
	&:nth-child(2),
	&:nth-child(4)
		.text
			max-width: 76%

	& + & .in::before
		content: ''
		height: 0.625rem - $-inner-gap

	&.is_sender_local
		--ChatBubble-backgroundColor: #{common.$blue}
		--ChatBubble-color: #ffffff
		--ChatBubble-justify: end
		--ChatBubble-origin-x: 100%

	&.is_sender_remote
		--ChatBubble-backgroundColor: #f7f7f7
		--ChatBubble-color: #000000
		--ChatBubble-justify: start
		--ChatBubble-origin-x: 0%

	&.is_active
		grid-template-rows: 1fr

.in
	display: grid
	row-gap: $-inner-gap
	font-size: 0.75rem
	font-weight: 500
	line-height: 1.36
	justify-items: var(--ChatBubble-justify)
	overflow: hidden
	transform-origin: var(--ChatBubble-origin-x) 0
	transform: scale(0) translateX(calc(-0.5 * (100% - 2 * var(--ChatBubble-origin-x))))
	transition-property: transform, opacity
	transition-duration: $-animation-duration
	transition-delay: $-animation-duration
	opacity: 0

	.is_active &
		transform: scale(1)
		transition-delay: 0
		opacity: 1

.text
	max-width: 90%
	border-radius: $-border-radius
	padding: 0.4375rem 0.625rem
	background-color: var(--ChatBubble-backgroundColor)
	color: var(--ChatBubble-color)

.images
	display: grid
	gap: $-inner-gap
	grid-template-columns: 1fr 1fr
	grid-template-rows: 1fr 1fr
	max-width: 70%
	width: 100%

.image
	position: relative
	aspect-ratio: 1 / 1
	border-radius: $-border-radius
	overflow: hidden
	transform: scale(0.5)
	transition-property: transform, opacity
	transition-duration: $-animation-duration
	opacity: 0

	.is_active &
		transform: scale(1)

		@for $i from 1 through 8
			&:nth-child(#{$i})
				transition-delay: 1.5 * $-animation-duration + 0.1s * $i
				opacity: 1

	&::before
		content: ''
		position: absolute
		inset: 0
		background-color: currentColor
		opacity: 0.2
