@use 'common'

.wrapper
	//

.header
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	gap: 1em
	margin-bottom: 2em

	@media not all and (min-width: common.$break62)
		align-items: center

.content
	//

.primary
	display: flex
	flex-wrap: wrap
	gap: 1em
