@use 'common'

.wrapper
	margin-top: 2.5rem

	@media (min-width: common.$break48)
		margin-top: 5rem

	.is_desktop
		@media not all and (min-width: common.$break48)
			display: none
			margin-top: 5rem

	.is_mobile
		@media (min-width: common.$break48)
			display: none
			margin-top: 2.5rem

.list
	list-style-type: none
	padding: unset
	margin: unset

	display: flex
	flex-direction: column
	gap: 1.5625rem

.item

	&:nth-child(2)
		text-align: right

	&:nth-child(3)
		text-align: center

	&:nth-child(4)
		text-align: right

	&:nth-child(5)
		text-align: center

	&:nth-child(6)
		text-align: right

	&:nth-child(7)
		//

	&:nth-child(8)
		text-align: center

	&:nth-child(9)
		text-align: right

	&:nth-child(10)
		text-align: center

.mobileItem
	text-align: center

.mobileVideoCard
	padding: unset
	margin: unset
	border: unset

	display: flex
	position: relative
	aspect-ratio: calc(var(--RevealableMediaContentItem-poster-width) / var(--RevealableMediaContentItem-poster-height))
	width: 100%
	z-index: 0

	&::after
		content: ''
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: .4s
		background-color: rgba(#000000, .2)
		position: absolute
		inset: 0
		z-index: 0

	&:hover, &:focus-visible
		&::after
			visibility: initial
			opacity: 1

.poster
	width: 100%
	height: 100%

.playButton
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	display: flex
	font-size: 6.25rem
	color: #FFFFFF
	z-index: 1
