@use 'common'

.thankYouWrapper
    width: 100%
    display: flex
    justify-content: space-between
    gap: 2rem
    background: #99D2FB
    padding: 8rem 5rem
    margin-top: 4rem
    border-radius: 3.5rem
    margin-bottom: 3.5rem

.leftSide
    width: 45%
    max-width: 290px
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 0rem 0rem 5rem 0rem

.leftSideTitle
    color: #0A0A0E
    font-size: 4rem
    line-height: 70px
    letter-spacing: -2.56px
    font-family: common.$objectSans
    margin: unset
    padding: unset
    margin-bottom: 1rem

    svg
        bottom: -45% !important

.leftSideQuote
    font-size: 1.2rem
    font-family: common.$sourceSans3
    font-weight: 400
    line-height: 30px
    margin-bottom: 1.5rem

.rightSide
    width: 55%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 1rem

.card
    background: white
    border-radius: 1.5rem
    padding: 5rem 2rem
    display: flex
    flex-direction: column
    max-width: 434px
    background-color: #E2F1FD

.cardContentWrapper
    display: flex
    flex-direction: column
    gap: 1.5rem

.cardTitleContainer
    display: flex
    align-items: center
    gap: 0.25rem

.cardTitle
    margin: unset
    padding: unset
    font-family: common.$sourceSans3
    font-weight: 400
    font-size: 1.5rem
    line-height: 26px
    color: #0A0A0E

.cardDescription
    font-family: common.$sourceSans3
    font-weight: 400
    font-size: 1.125rem
    line-height: 26px
    color: #0A0A0E
    margin: unset
    padding: unset

.linkButton
    color: white
    padding: 1rem 1.25rem
    border-radius: 0.5rem
    background: #1B00FB
    font-family: common.$sourceSans3
    font-weight: 400
    font-size: 1.125rem
    font-weight: 500
    line-height: 26px
    max-width: 150px
    text-align: center

@media (max-width: common.$break62)
    .thankYouWrapper
        margin-bottom: 1.5rem
        padding: 2rem
        border-radius: 0

    .leftSide
        width: 100%
        padding: 0rem
    .rightSide
        width: 100%
        max-width: 400px
    .thankYouWrapper
        flex-direction: column
    .leftSideTitle
        font-size: 2rem
        margin-bottom: 1rem
        line-height: 40px
        letter-spacing: -0.64px
    .leftSideQuote
        font-size: 18px
        line-height: 26px
        margin-bottom: 2rem
    .card
        padding: 2rem
