@use 'common'

.wrapper
	margin-bottom: 6rem

.title
	+common.title

.leadParagraph
	font-weight: 700
	font-size: 1.625rem
	line-height: 2rem

.info
	display: flex
	align-items: center
	margin: 2.5rem 0 3.75rem

.readingTime
	margin: 0 2rem 0 0
	@media (min-width: common.$break48)
		margin: 0 4rem 0 0
