@use 'common'

.blockTitle
    margin-block: 0
    font-size: 2rem
    font-weight: 400
    line-height: 2.5rem
    color: #000
    letter-spacing: -0.04rem
    font-family: common.$objectSansStack
    @media (min-width: common.$break62)
        font-size: 1.5rem
        line-height: 2rem
        letter-spacing: -0.03rem

.linksContainer
    margin-top: 1.75rem
    display: flex
    flex-direction: column
    padding-inline-start: 1.625rem
    color: #000
    @media (min-width: common.$break62)
        gap: 0.625rem

.linkTitle
    margin-block: 0
    font-size: 1.125rem
    font-weight: 400
    line-height: 2rem
    letter-spacing: -0.025rem
    text-decoration: underline
    font-size: common.$objectSansStack
    @media (min-width: common.$break62)
        text-decoration: none
        line-height: 1.625rem
