@use '../variables'

=inputIsHighlighted
	animation-name: blinking
	animation-duration: 3s
	animation-iteration-count: infinite

	@keyframes blinking
		0%
			box-shadow: 0 0 .625rem variables.$blue

		50%
			box-shadow: 0 0 0 transparent

		100%
			box-shadow: 0 0 .625rem variables.$blue
