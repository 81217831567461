@use 'common'

.wrapper
    display: flex
    flex-direction: column
    width: 100%
    padding: 0rem 0rem 4rem 0rem
    gap: 2.5rem
    @media (min-width: common.$break62)
        flex-direction: row
        padding: 0rem 0rem 2rem 0rem

.textContainer
    display: flex
    width: 100%
    height: 100%
    position: relative
    justify-content: space-between
    @media (min-width: common.$break62)
        width: 42%
        position: relative

.title
    margin-block: 0
    font-weight: 400
    font-size: 2rem
    line-height: 2.5rem
    @media (min-width: common.$break62)
        font-size: 3.125rem
        line-height: 3.5rem

.accordionsContainer
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    gap: 0.87rem
    @media (min-width: common.$break62)
        width: 58%

    & > :not(:last-child)
        border-bottom: 1px solid #C8C8C8
.textBox
    max-width: 19.375rem
    width: 100%

.arrow
    transform: rotateZ(150deg)
    margin-top: 1rem
    width: 119px
    height: 64px

    @media (min-width: common.$break62)
        transform: rotateZ(0deg)
        position: absolute
        bottom: -80%
        right: 23%
        height: 128px
        width: 238px
