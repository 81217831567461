@use 'common'

.wrapper
	+common.input
	width: 100%
	resize: none
	font-family: inherit
	min-height: 9.125rem
	font-size: 1em
	line-height: common.$line-height

	&.view_compact
		+common.inputIsCompact

	&.is_highlighted
		+common.inputIsHighlighted
