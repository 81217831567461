@use 'common'
@use 'sass:math'

$-transition-duration: 0.4s

.wrapper
	position: relative
	margin-inline: auto
	width: calc(100% - common.$gemma-chat-width * 2)
	padding-inline: 1.5rem

.in
	display: flex
	align-items: flex-end
	text-align: center

.content
	flex-shrink: 0
	flex-basis: 100%
	display: flex
	justify-content: center

	&:not(:first-child)
		margin-left: -100%

	.title,
	.text
		opacity: 0
		visibility: hidden
		transform: scale(0.9)
		transition-property: opacity, visibility, transform
		transition-duration: math.div($-transition-duration, 2)

	&.is_active
		.title,
		.text
			visibility: inherit
			opacity: 1
			transform: scale(1)
			transition-duration: $-transition-duration

.title
	margin: 0
	font-size: 1.4rem
	line-height: 1
	font-weight: 700
	max-width: 45rem

	@media (min-width: common.$break92)
		font-size: 2.25rem

.text
	margin: 0
	line-height: 1.625
	max-width: 27rem
	margin-inline: auto

	&:not(:empty)
		margin-top: 0.7rem

		@media (min-width: common.$break92)
			margin-top: 1rem

	&_in
		opacity: 0.7
