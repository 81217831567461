@use 'common'

.imageWrapper
	max-width: calc(var(--Image-imageWrapper-maxWidth, var(--content-width-normal)) + 2 * var(--global-horizontal-spacing))
	margin: auto
	padding: 0 var(--global-horizontal-spacing)

.sourceContainer
	display: flex
	width: 100%
	justify-content: center

.imageSource
	font-size: 0.875rem
	font-weight: 400
	line-height: 1.375rem
	margin-top: 1rem
	@media (min-width: common.$break62)
		font-size: 1.125rem
		font-weight: 400
		line-height: 1.625rem
		margin-top: 1.5rem

.roundedStyles
	border-radius: 1rem
	overflow: hidden
	@media (min-width: common.$break62)
		border-radius: 2rem
