@use 'common'

$-vertical-padding: 1rem
$-horizontal-padding: 1.25rem
$-break: common.$break62

.modal
	color-scheme: light
	position: absolute
	top: 1rem
	left: var(--global-horizontal-spacing)
	right: var(--global-horizontal-spacing)
	bottom: 1rem
	margin: auto
	max-width: 30em
	height: min-content
	border-radius: 0.625em
	overflow: hidden
	display: grid
	background-color: #FFC445

	@media (min-width: $-break)
		max-width: 58em
		aspect-ratio: 920 / 338

.overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background-color: rgba(40, 47, 52, 0.8)

.in
	padding: $-vertical-padding $-horizontal-padding
	display: grid
	grid-template-columns: 1fr auto
	gap: 1.625em

	@media (min-width: $-break)
		grid-template-columns: 1fr 1fr auto

.illustration
	position: relative
	margin: (-1 * $-vertical-padding) 1.625em (-1 * $-vertical-padding) (-1 * $-horizontal-padding)

	@media not all and (min-width: $-break)
		display: none

.form
	display: grid
	align-self: center
	gap: 1.625em

.title
	margin: 0
	text-align: center
	color: #000000
	font-size: 1.5em
	font-weight: 500

.close
	font-size: 1.25em
