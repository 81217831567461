@use 'common'

$-border-color: #d9d9d9
$-break: common.$break38

.wrapper
	border-radius: common.$border-radius
	border: 1px solid $-border-color
	box-shadow: 0px 40px 40px rgba(#000000, 0.04)
	display: grid
	grid-template-columns: 1fr 1fr
	background-color: #ffffff

	@media (min-width: $-break)
		grid-template-columns: auto 1fr auto

.logo,
.action,
.about
	padding: 1.5em 2em

.logo,
.action
	display: flex
	align-items: center
	justify-content: center

	@media (min-width: $-break)
		min-width: 9em

.action

	@media (min-width: $-break)
		aspect-ratio: 1 / 1

.logo
	&_in
		position: relative
		max-width: 4em
		width: 100%
		aspect-ratio: 1 / 1

.about
	display: grid
	gap: 0.875em
	grid-template-rows: auto 1fr
	order: -1
	grid-column: 1 / -1

	@media (min-width: $-break)
		order: initial
		grid-column: initial
		border-left: 1px solid $-border-color
		border-right: 1px solid $-border-color

.name
	margin: 0
	font-weight: 500
	font-size: 1em
	color: #1b1b26
	line-height: 1.375em

.description
	margin: 0
	font-size: 0.875em
	line-height: 1.36em
	color: #767676
