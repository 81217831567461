@use 'common'

.sectionWrapper
    position: relative
    width: 100vw
    max-width: 1200px
    margin: auto

    padding-left: 0rem

    & .sectionContent
        @media (max-width: common.$break32)
            padding-left: 2rem

        @media (max-width: common.$break48)
            padding-left: 2rem

        @media (max-width: common.$break62)
            padding-left: 2rem

    @media (max-width: common.$break75)
        max-width: 800px

    @media (max-width: common.$break81)
        max-width: 1000px

.tilesContainer
    display: flex
    flex-direction: row
    overflow-x: auto
    gap: 1rem
    padding: 1rem 0rem

    scrollbar-width: none
    -ms-overflow-style: none

    &::-webkit-scrollbar
        display: none

.tileWrapper
    flex: 0 0 auto
    border-radius: 2rem
    background: var(--Neutral-Black-4, #F5F5F5)
    scroll-snap-align: start
    transition: transform 0.3s ease
    overflow: hidden

    max-width: 16rem

    &:last-child
        margin-right: 2rem

        @media (min-width: common.$break62)
            margin-right: 0

    @media (max-width: common.$break32)
        max-width: 20rem

    @media (max-width: common.$break48)
        // max-width: 24rem

    & .content
        display: flex
        flex-direction: column
        padding: 2.5rem
        align-items: flex-start
        gap: .75rem

.desc
    color: var(--Neutral-Black, #0A0A0E)

    font-family: common.$sourceSans3
    font-size: 0.875rem
    font-style: normal
    font-weight: 400
    line-height: 1.375rem

.heading
    margin: 0 !important
    color: var(--Neutral-Black, #0A0A0E)

    font-family: common.$objectSans
    font-size: 1.25rem
    font-style: normal
    font-weight: 400
    line-height: 1.75rem
    letter-spacing: -0.025rem

.prevBtn,
.nextBtn
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 50%
    transform: translateY(-50%)
    color: #000
    background: #fff
    border: none
    cursor: pointer
    z-index: 1
    width: 3.5rem
    height: 3.5rem
    flex-shrink: 0
    border-radius: 100%
    fill: var(--Neutral-White, #FFF)
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.20))

.prevBtn
    left: -2rem

    @media (max-width: common.$break62)
        left: .5rem

.nextBtn
    right: -2rem

    @media (max-width: common.$break62)
        right: .5rem

.buttonsContainer
    display: flex
    gap: 1rem
    justify-content: center
    align-items: center
    padding-top: 3rem
    @media (min-width: common.$break32)
        padding-top: 4rem
