@use 'common'

.wrapper
	--CardTileContent-text-topSpacing: .625rem
	--CardTileContent-mainContent-paddingBlock: 2.5rem

	@media (min-width: common.$break30)
		--CardTileContent-mainContent-paddingBlock: 1.875rem

.main
	position: relative
	display: grid
	grid-template-columns: 1fr 1fr
	gap: .75rem
	z-index: 1

	@media (min-width: common.$break38)
		grid-template-columns: 1fr 1fr 1fr
		gap: 1.875rem

	@media (min-width: common.$break48)
		grid-template-columns: 1fr 1fr 1fr 1fr

	@media (min-width: common.$break62)
		grid-template-columns: 65% 1fr 1fr

.mediaContentList
	position: absolute
	inset: 0
	z-index: -1

	@media not all and (min-width: common.$break38)
		&.is_mobileOpen
			z-index: 1

	@media (min-width: common.$break38)
		position: relative
		z-index: 0
		grid-column: span 2
		grid-row: span 2

		&.is_desktopOpen
			z-index: 1

	@media (min-width: common.$break62)
		grid-column: span 1
		grid-row: span 3

.tab
	padding: unset
	margin: unset
	border: unset
	background-color: transparent

	display: flex
	flex-direction: column
	gap: 1.5rem
	justify-content: center
	align-items: center
	position: relative
	width: 100%
	aspect-ratio: 1
	display: flex
	justify-content: center
	align-items: center
	z-index: 0

	&::after
		content: ''
		position: absolute
		inset: 0
		box-shadow: common.$box-shadow
		border-radius: 1.875rem
		z-index: -1

	@media (min-width: common.$break38)
		&::after
			visibility: hidden
			opacity: 0
			transition-property: visibility, opacity
			transition-duration: 0s

		&.is_desktopActive
			&::after
				visibility: initial
				opacity: 1

.plus
	width: 1.25rem
	height: 1.25rem
	position: relative
	z-index: 0

	@media (min-width: common.$break62)
		display: none

	&::before, &::after
		content: ''
		position: absolute
		top: 50%
		left: 50%
		width: 100%
		height: .25rem
		background-color: #808080
		z-index: 0

	&::before
		transform: translate(-50%, -50%)

	&::after
		transform: translate(-50%, -50%) rotate(90deg)

.card
	position: absolute
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity

	// the opened card completely cover 3 row of tabs on mobile
	@media (min-width: 23rem) and (max-width: common.$break30 - .0625rem)
		aspect-ratio: 351 / 533

	@media (min-width: common.$break38)
		&.is_desktopOpen
			position: initial
			visibility: initial
			opacity: 1

	&.is_open
		position: initial
		visibility: initial
		opacity: 1

	&Close
		background-color: #FFFFFF
		padding: unset
		margin: unset
		border: unset

		width: 2.5rem
		height: 2.5rem
		position: absolute
		top: -0.625rem
		right: -0.625rem
		border-radius: 50%
		box-shadow: 0 0 1.25rem rgba(#000000, .25)
		pointer-events: all
		z-index: 1

		@media (min-width: common.$break38)
			display: none

		&::before, &::after
			content: ''
			position: absolute
			top: 50%
			left: 50%
			width: 1.25rem
			height: 3px
			background-color: #000000
			z-index: 0
			transform-origin: center

		&::before
			transform: translate(-50%, -50%) rotate(45deg)

		&::after
			transform: translate(-50%, -50%) rotate(-45deg)

	&Poster
		background-color: transparent
		padding: unset
		margin: unset
		border: unset

		display: flex
		width: 100%
		position: relative
		aspect-ratio: calc(var(--TabsWithRevealableMediaContent-poster-width) / var(--TabsWithRevealableMediaContent-poster-height))
		z-index: 0
		object-fit: cover

		&.is_mobile
			aspect-ratio: calc(var(--TabsWithRevealableMediaContent-posterMobile-width) / var(--TabsWithRevealableMediaContent-posterMobile-height))
			@media (min-width: common.$break38)
				display: none

		&.is_desktop
			aspect-ratio: calc(var(--TabsWithRevealableMediaContent-poster-width) / var(--TabsWithRevealableMediaContent-poster-height))
			@media not all and (min-width: common.$break38)
				display: none

		&In
			width: 100%
			height: 100%

		&::after
			content: ''
			visibility: hidden
			opacity: 0
			transition-property: visibility, opacity
			transition-duration: .4s
			background-color: rgba(#000000, .2)
			position: absolute
			inset: 0
			z-index: 0

		&:hover, &:focus-visible
			&::after
				visibility: initial
				opacity: 1

		.playButton
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
			display: flex
			font-size: 6.25rem
			color: #FFFFFF
			z-index: 1

.tags
	list-style-type: none
	padding: unset
	margin: unset
	display: flex
	flex-wrap: wrap
	gap: .625rem
	margin-bottom: .9375rem

.tag
	padding: .5rem .9375rem
	background-color: common.$dark
	color: #FFFFFF
	border-radius: .3125rem
	line-height: 1
