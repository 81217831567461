@use 'common'

.wrapper
	// @TODO: mobile ??

	&.is_background_blue, &.is_background_black
		.content
			&Title
				color: inherit

.title
	+common.heading3
	text-align: center

.text
	text-align: center
	margin: unset
	opacity: var(--text-opacity)

.main
	display: flex
	gap: 1.25rem

.column
	$firstColumnWidth: 45%

	&:first-child
		flex-basis: $firstColumnWidth

	&:last-child
		flex-basis: 100% - $firstColumnWidth

.tabs
	list-style-type: none
	padding: unset
	margin: unset

	gap: 1.25rem
	margin-top: 1.25rem
	display: flex
	align-content: center
	width: fit-content
	border-radius: common.$border-radius
	margin-bottom: 5rem

.tab

	&Button
		background-color: unset
		padding: unset
		border: unset
		font-family: inherit

		padding: .8125rem 1.5rem
		border-radius: common.$border-radius
		font-size: 1rem
		font-weight: 500
		border-style: solid
		border-width: 1px
		border-color: transparent
		transition-property: color, border-color
		transition-duration: .2s
		color: #747885

		&:hover, &:focus-visible
			color: common.$chartreuse

		&.is_active
			border-color: common.$chartreuse
			color: common.$chartreuse

.media
	display: flex
	justify-content: center
	align-content: center
	position: absolute
	top: 0
	left: 0
	right: 0
	z-index: 0
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity
	transition-duration: .2s
	min-height: 35.6875rem

	&.is_active
		position: relative
		visibility: initial
		opacity: 1

.image
	max-width: 100%
	height: auto

.content
	max-width: 25.625rem
	display: flex
	flex-direction: column
	justify-content: center
	align-content: center
	position: absolute
	top: 0
	left: 0
	right: 0
	z-index: 0
	visibility: hidden
	opacity: 0

	&.is_active
		position: relative
		visibility: initial
		opacity: 1

	&Title
		font-size: 2.5rem
		line-height: 1.25
		margin-top: unset

	&Text
		margin-block: unset

	&Form
		margin-top: 1.875rem
