=hideHorizontalScrollbarOnTouchDevice
	overflow-x: auto

	@media not all and (pointer: fine)
		-webkit-overflow-scrolling: touch
		scrollbar-width: none
		scrollbar-height: none
		-ms-overflow-style: none

		&::-webkit-scrollbar
			display: none
