@use 'common'

.wrapper
    // padding: 0 1.5rem
    display: flex
    align-items: center
    flex-direction: column
    gap: 12px
    padding-bottom: 3rem

.header
    display: flex
    align-items: center
    flex-direction: column
    padding-bottom: 2rem
    font-family: common.$objectSans
    font-size: 2rem
    color: #0A0A0E

.title
    text-align: center
    margin: unset
    color: #870B46

.titleSecondary
    margin: unset
    color: '#0A0A0E'
    text-align: center

.cardContainer
    width: 100%
    background: #FAFAFA
    padding: 1.5rem
    border-radius: 1rem

.cardDescription
    color: #0A0A0E
    font-size: 1.5rem
    font-family: common.$objectSans
    line-height: 2rem
    letter-spacing: -0.48px

.cardAuthor
    font-size: 0.875rem
    color: #0A0A0E
    font-family: common.$sourceSans3
    font-weight: 400

.coloredBoxContainer
    width: 100%
    border-radius: 1rem
    padding: 2rem 1.5rem
    font-family: common.$objectSans
    position: relative

.percentage
    font-size: 3.125rem
    margin: unset
    font-family: common.$objectSansStack
    line-height: 56px
    letter-spacing: -2px

.message
    margin: unset
    font-size: 1.25rem
    line-height: 28px
    letter-spacing: -0.4px

.damnWrapper
    position: absolute
    top: 5%
    right: -5%
    height: 1.25rem
    width: 5rem

.shoulderBrushingWrapper
    position: absolute
    top: -3%
    right: -3%
    height: 1.25rem
    width: 12rem
