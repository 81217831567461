@use 'common'

.wrapper
	position: absolute
	inset: 0
	display: grid
	grid-template-columns: 0 2fr 3fr
	column-gap: 5em
	padding: 3em 2em 3em 0

	&::before
		content: ''

.content
	display: grid
	align-self: end
	grid-template-columns: 1fr 1fr 1fr
	column-gap: 1em
	padding-bottom: 10%

.title
	grid-column: 1 / -1

.text
	grid-column: 1 / -1
	margin-block: 1em 2em

.icon
	aspect-ratio: 1 / 1
	display: grid

.image
	display: grid
