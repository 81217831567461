@use 'common'

.wrapper
	display: grid
	margin-bottom: 1em

.list
	+common.hideHorizontalScrollbarOnTouchDevice
	scroll-snap-type: x mandatory
	display: flex
	gap: 1em
	list-style-type: none
	padding: 0
	margin: 0
	color: #ffffff

.item
	width: 13em
	flex-shrink: 0
	aspect-ratio: 203 / 99
	scroll-snap-align: center
	margin-bottom: 0.5em

	&Link
		position: relative
		z-index: 0
		display: flex
		justify-content: center
		align-items: center
		text-align: center
		font-size: 1.125em
		font-weight: 500
		padding: 1em
		min-height: 100%

	&Background
		position: absolute
		inset: 0
		z-index: -1
		border-radius: 0.625em
		background-color: rgba(#000000, 0.5)
		overflow: hidden
		opacity: 0.3
		transition-property: opacity
		transition-duration: 0.3s

		.is_active &
			opacity: 1

	&:not(.is_active) &Link:hover &Background
		opacity: 0.5
