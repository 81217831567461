@use 'common'
@use 'sass:math'

$salmonColor: #FF9A9A

.box
	--Box-inner-horizontal-spacing: 1.875rem
	--Box-inner-vertical-spacing: 2rem

	display: flex
	flex-direction: column
	border-radius: 1.25rem
	position: relative
	min-height: 25rem

	&.is_black
		background-color: #000000

	&.is_blue
		background-color: common.$blue

	&.is_blue, &.is_black
		color: #FFFFFF

		.title
			color: inherit

	&.is_transparent
		--Box-inner-vertical-spacing: 0
		background-color: transparent

		&.boxCropped
			padding: 0

			.titleContainerCropped
				padding-block: 0

	@media (min-width: common.$break48)
		--Box-inner-vertical-spacing: 6rem
		--Box-inner-horizontal-spacing: 6rem

.boxCropped
	margin-bottom: -5rem

	margin-left: -1.5rem
	margin-right: -1.5rem
	border-radius: 0
	background-color: #031C2E !important
	padding: 2.5rem 0

	@media (min-width: common.$break48)
		margin-bottom: 0
		margin: 0
		border-radius: 3.5rem

	@media (min-width: common.$break62)
		border-radius: 3.5rem
		padding: 8.5rem 0

		.textWrapper
			align-self: flex-start

.titleContainer
	display: flex
	flex-direction: column
	padding: var(--Box-inner-vertical-spacing) var(--Box-inner-horizontal-spacing)
	z-index: 2
	padding-bottom: 4rem

	@media (min-width: common.$break62)
		flex-direction: row

.titleContainerCropped
	display: flex
	flex-direction: column
	gap: 0
	width: 100%

	padding: var(--Box-inner-vertical-spacing) var(--Box-inner-horizontal-spacing)
	@media (min-width: common.$break48)
		padding-top: 2rem
		padding-bottom: 4rem
		gap: 2.74rem

.title
	+common.title
	color: inherit
	margin: 0

	@media (min-width: common.$break62)
		padding-right: 2rem
		width: 75%

.highlightedTitles
	display: grid
	color: $salmonColor
	overflow: hidden

	&Layer
		display: grid
		grid-column: 1
		grid-row: 1

.highlightedTitle
	width: 100%
	flex-shrink: 0
	z-index: 0
	visibility: hidden
	opacity: 0
	grid-row: 1
	grid-column: 1

	&.is_visible
		visibility: initial
		opacity: 1

.character
	color: transparent
	position: relative

	&.is_lastVisible
		&::after
			content: '|'
			color: $salmonColor
			position: absolute
			inset: 0 auto 0 100%

	&:last-child::after
		animation-name: flashingVerticalLine
		animation-duration: 500ms
		animation-iteration-count: infinite
		animation-timing-function: step-end

	&.is_visible
		color: inherit

	&.is_visible + &:not(.is_visible)
		&::after
			visibility: initial

@keyframes flashingVerticalLine
	0%
		opacity: 0
	50%
		opacity: 1

.titleCropped
	font-family: common.$objectSansStack
	font-weight: 400 !important
	position: relative

	@media (min-width: common.$break30)
		width: 100%

	@media (min-width: common.$break38)
		width: 100%

	@media (min-width: common.$break62)
		width: 100%

.textWrapper
	&.is_cropped
		width: 100% !important

		@media (min-width: common.$break48)
			width: 55% !important

	@media (min-width: common.$break62)
		width: 25%
		align-self: flex-end

.text
	line-height: 1.77
	margin: 0
	margin-top: 2rem
	@media (min-width: common.$break22)
		font-size: 1.125rem
	@media (min-width: common.$break62)
		font-size: 1.25rem
		margin-top: 0
		line-height: common.$line-height

.button
	margin-top: 2.5rem

.content
	padding: 0 var(--Box-inner-horizontal-spacing) var(--Box-inner-vertical-spacing)

	@media (min-width: common.$break48)
		--Box-content-bottom-spacing: var(--Box-inner-vertical-spacing)
		--Box-inner-horizontal-spacing: 6rem

.imageWithoutBottomSpace
	margin-bottom: calc(var(--Box-content-bottom-spacing) * -1)

.imageWithoutSideSpace
	//

.imageWrapper
	width: 50%
	position: absolute
	top: -12.5%

.textsWrapper
	display: flex
	flex-wrap: wrap
	margin-left: -1.8rem
	margin-bottom: 2rem
	@media (min-width: common.$break48)
		margin-bottom: 4rem

.texts
	width: 100%
	padding: 0 1.8rem
	margin-bottom: 1rem
	@media (min-width: common.$break48)
		width: 50%
		margin-bottom: 2rem
	@media (min-width: common.$break75)
		width: math.div(100%, 3)

.contentTitle
	font-size: 1.25rem
	line-height: 1.3em
	font-weight: 700

.icons
	margin-bottom: 3rem
