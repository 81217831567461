@use 'common'
@use 'sass:math'

.wrapper
	display: grid

.container
	overflow: hidden

.in
	display: flex
	flex-direction: column
	position: relative
	padding: 2rem 0
	font-size: min(10vw, 6.9688rem)
	margin: auto
	max-width: common.$break92

.contentWrapper
	z-index: 2
	@media (min-width: common.$break48)
		padding: 0 1rem
	@media (min-width: common.$break62)
		padding: 0 2rem
	@media (min-width: common.$break75)
		padding: 0 3rem

.textLinkWrapper
	display: flex
	flex-direction: column

.title
	+common.title
	margin: 0
	@media (min-width: common.$break48)
		font-size: 2em
		line-height: 0.8em
		font-weight: 700
		margin-top: 1em
		margin-bottom: 0

	@media (min-width: common.$break62)
		font-size: 1.6em

.text
	font-size: 1.25rem
	line-height: 2.0625rem
	margin: 2rem 0 3rem
	max-width: 25rem
	padding-right: 2rem

.link
	font-size: 1rem

.bubblesWrapper
	display: flex
	flex-direction: column
	top: 0
	margin: 2rem 0
	@media (min-width: common.$break48)
		position: absolute
		inset: 7.5rem 0 0

.bubbles
	display: flex
	align-items: center
	justify-content: center
	margin: 0.5rem 0
	@media (min-width: common.$break48)
		transform: translateX(calc(1px * var(--HeroDynamic-offset) / var(--HeroDynamic-distance)))

		&:nth-child(1),
		&:nth-child(3),
		&:nth-child(4)
			justify-content: flex-end
			margin: 1.25rem 0
		&:nth-child(2),
		&:nth-child(5)
			justify-content: space-between
			margin: 1.25rem 0

		&:nth-child(2)
			margin: 4rem 0 1rem

		&:nth-child(1)
			--HeroDynamic-distance: 3
		&:nth-child(2)
			--HeroDynamic-distance: 2
		&:nth-child(3)
			--HeroDynamic-distance: 2.5
		&:nth-child(4)
			--HeroDynamic-distance: 4
		&:nth-child(5)
			--HeroDynamic-distance: 3.2

.circle,
.bubble
	height: 2em
	width: calc(var(--bubble-size) * 2)
	@media (min-width: common.$break48)
		height: 1em
		width: var(--bubble-size)

.is_view_left
	position: relative
	left: -2rem
	@media (min-width: common.$break48)
		left: -4.6875rem

.is_view_right
	position: relative
	right: -2rem
	@media (min-width: common.$break48)
		right: -4.375rem

.circle2
	margin-right: 2rem

.bubble6
	margin-right: 1rem
	@media (min-width: common.$break48)
		margin-right: 5rem

@media (min-width: common.$break48)
	.bubble1
		left: -18.75rem

	.bubble3
		left: -12.5rem
		z-index: 2

	.bubble4
		right: -8.75rem

	.bubble6
		right: -17.1875rem

	.bubble7
		right: -10.625rem
