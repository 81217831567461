@use './input'
@use '../variables'

=textArea
	+input.input
	width: 100%
	resize: none
	font-family: inherit
	min-height: 9.125rem
	font-size: 1em
	line-height: variables.$line-height
