@use 'common'

$-break: common.$break62
$-navigation-width: 18rem

.wrapper
	display: grid

	@media (min-width: $-break)
		grid-template-columns: $-navigation-width 1fr

		&.is_hideNavigation
			grid-template-columns: none

	&.is_dark
		background-color: #2E2E35
		color: #FFFFFF

.in
	padding: 2em var(--global-horizontal-spacing) 5rem
	display: flex

	.is_hideNavigation &
		padding: 0

	@media (min-width: $-break)
		&::after
			content: ''
			max-width: $-navigation-width
			flex-grow: 1

			.is_hideNavigation &
				display: none

.navigation
	position: absolute
	top: 0
	left: 0
	right: 0
	transform: translateX(-100%)
	visibility: hidden
	display: grid

	@media (min-width: $-break)
		position: static
		transform: none
		visibility: initial

		.is_hideNavigation &
			display: none
