@use 'common'

.wrapper
	//

.main
	display: flex

	.placeholder
		display: block
		overflow-x: auto
		padding: 0.5em
		color: #ffffff
		background: #1c1b1b

	.code
		border-radius: .5rem
		max-width: 50rem
		white-space: pre-wrap
		padding: 1.25rem
