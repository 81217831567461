@use 'common'

=arrow
	content: ""
	display: block
	position: absolute
	bottom: -5px
	right: -5px
	width: 0
	height: 0
	border-top: 5px solid transparent
	border-left: 10px solid #000000
	border-bottom: 5px solid transparent

.appTimeline
	display: flex
	flex-direction: column
	align-items: center
	padding: 0 1rem

.groups
	display: flex
	flex-direction: column
	margin-top: 2rem
	@media (min-width: common.$break48)
		flex-direction: row

.group
	display: flex
	flex-direction: column-reverse
	align-items: center
	&:nth-child(1)
		.groupImages
			border-left: none

	@media (min-width: common.$break48)
		flex-direction: column
		&:nth-last-child(1)
			.groupImages::after
				+arrow

.groupImages
	position: relative
	display: flex
	justify-content: center
	flex-wrap: wrap
	padding: 1rem
	padding-bottom: 3rem
	@media not all and (min-width: common.$break48)
		&::before
			border-left: 1px solid #000000
			content: ""
			display: block
			height: 1.875rem
			position: absolute
			bottom: 0
			right: 0
			left: 0
			margin: auto
			width: 0
		&::after
			+arrow
			transform: rotate(90deg)
			left: 0
			right: 0
			margin: auto

	@media (min-width: common.$break48)
		border-bottom: 1px solid #3b3b3b
		border-left: 1px solid #3b3b3b

.image
	position: relative
	width: 3.4375rem
	height: 3.4375rem
	margin: 0.5rem

.title
	font-weight: 700
	margin: 2rem 0 0
	@media (min-width: common.$break48)
		margin: 1rem
