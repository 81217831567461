@use 'common'

@keyframes click
	0%
		transform: scale(1)
	50%
		transform: scale(0.8)
	100%
		transform: scale(1)

.wrapper
	display: flex
	font-size: 2.625rem

	&.is_resting
		animation: click 0.2s
