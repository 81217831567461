@use 'common'

.wrapper
	display: flex
	flex-direction: column
	height: 100%
	box-shadow: common.$box-shadow
	background-color: #FFFFFF

	&.is_borderRadius
		&_default
			border-radius: 1.25rem

		&_big
			border-radius: 1.75rem

	&.is_overflowPrevented
		overflow: hidden
		transform: translateZ(0)
