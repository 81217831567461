@use 'common'

.rightSide
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 1rem
    color: #ffffff

.card
    border-radius: 1.0625rem
    padding: 7rem 5rem
    display: flex
    flex-direction: column
    background-color: rgba(255, 255, 255, 0.15)
    max-width: 30.3245rem

.cardContentWrapper
    display: flex
    flex-direction: column
    gap: 1.5rem

.cardTitleContainer
    display: flex
    align-items: center
    gap: 0.25rem

.cardTitle
    margin: unset
    padding: unset
    font-family: common.$fustat
    font-size: 3rem
    line-height: 3.5rem
    letter-spacing: -0.06rem

.cardDescription
    font-family: common.$sourceSans3
    font-size: 1rem
    line-height: 1.5rem
    margin: unset
    padding: unset

.linkButton
    color: var(--Light-40-Ceros-Blue-40, #907EFF)
    leading-trim: both
    text-edge: cap
    // font-family: "Source Sans Pro"
    font-family: common.$sourceSans3
    font-size: 1rem
    font-style: normal
    font-weight: 600
    line-height: 1.5rem
    text-decoration-line: underline
    text-decoration-style: solid
    text-decoration-skip-ink: none
    text-decoration-thickness: auto
    text-underline-offset: auto
    text-underline-position: from-font

@media (max-width: common.$break62)
    .card
        max-width: max-content !important

@media (max-width: common.$break75)
    .thankYouWrapper
        margin-bottom: 1.5rem
        padding: 2rem
        border-radius: 0
    .rightSide
        width: 100%
        padding: 1rem
    .thankYouWrapper
        flex-direction: column
    .leftSideTitle
        font-size: 2rem
        margin-bottom: 1rem
        line-height: 40px
        letter-spacing: -0.64px
    .leftSideQuote
        font-size: 18px
        line-height: 26px
        margin-bottom: 2rem
    .card
        padding: 2rem
