@use 'common'
@use 'sass:math'

.wrapper
	//

.image
	width: 100%
	margin-bottom: 25px
	aspect-ratio: math.div(329, 392)
	position: relative
	border-radius: 29px
	overflow: hidden

	@media (min-width: common.$break62)
		margin-bottom: 50px

.title
	margin: 0
	margin-bottom: 23px
	font-size: 26px
	line-height: 1

.text
	//
