@use 'common'

$fieldTopSpacing: .5em

.embedForm
	+common.form
	margin-top: -$fieldTopSpacing

	&.isInAsideContent
		:global
			.input

				input
					background-color: transparent
					// @TODO: color set according to theme
					color: #FFFFFF

					&::placeholder
						color: white

				select
					background-color: transparent
					color: #ffffff
					background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2218%22 height%3D%2215%22 viewBox%3D%220 0 18 15%22 fill%3D%22none%22%3E%3Cpath d%3D%22M1.77148 7L8.88577 13.168L16.0001 7%22 stroke%3D%22%23FFFFFF%22 stroke-width%3D%222%22 stroke-linecap%3D%22round%22/%3E%3C/svg%3E') !important

			.actions

				input
					background-color: white
					color: black

	&.is_waitlist
		:global
			.input

				input
					background-color: transparent
					// @TODO: color set according to theme
					color: #FFFFFF

		.buttonSubmit
			margin-top: 2.125rem

	&.isInLoginPage
		:global
			.input

				input
					background-color: #F5F5F5

					color: black

					&::placeholder
						color: black

				select
					background-color: #F5F5F5
					color: black

			.actions

				input
					background-color: black
					color: white

	.errorMessage
		+common.inputErrorMessage
		margin-bottom: unset
		list-style-type: none
		padding: 0

		li
			&:not(:first-child)
				margin-top: .3125rem

	fieldset
		width: 100%
		display: flex
		flex-direction: column
		gap: .625rem
		border: unset
		padding: unset
		margin: unset
		max-width: 100%

		div
			flex-grow: 1
			flex-shrink: 1

		@media (min-width: common.$break24)
			flex-direction: initial

	:global
		.field
			padding-top: $fieldTopSpacing

		.input

			input
				+common.input
				+common.inputIsCompact
				width: 100%

			textarea
				+common.textArea
				+common.inputIsCompact

			:local
				.is_error
					+common.inputIsError

			select
				+common.input
				+common.inputIsCompact
				width: 100%
				cursor: pointer
				background-color: white
				appearance: none

				background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%2218%22 height%3D%2215%22 viewBox%3D%220 0 18 15%22 fill%3D%22none%22%3E%3Cpath d%3D%22M1.77148 7L8.88577 13.168L16.0001 7%22 stroke%3D%22black%22 stroke-opacity%3D%220.5%22 stroke-width%3D%222%22 stroke-linecap%3D%22round%22/%3E%3C/svg%3E')
				background-repeat: no-repeat
				background-position: calc(100% - 20px) center
				background-size: 14px

	.buttonSubmit
		margin-top: $fieldTopSpacing
		min-height: 100%
