@use 'common'

.wrapper
    display: flex
    flex-direction: column
    background-color: #C5E7FF
    padding: 5rem 0rem
    gap: 3rem
    position: relative
    overflow: hidden

    @media (min-width: common.$break62)
        margin-bottom: -5rem
        flex-direction: row
        align-items: flex-start
        padding: 9rem 0rem

.leftSection
    flex: 0.55
    position: relative

    .title
        +common.title
        text-align: left !important
        font-weight: 400
        text-align: center
        margin: 2rem 0 0
        width: 70%
        margin-top: -1rem

        @media (min-width: common.$break62)
            width: 100%

    .text
        font-family: common.$objectSansStack
        max-width: fit-content
        text-align: left
        margin: 2rem 0
        font-size: 1.25rem
        line-height: 1.77em
        padding-right: 4rem

        @media (min-width: common.$break62)
            padding-right: 0rem
            max-width: 31.825rem
            line-height: 2rem
            font-size: 1.5rem
            letter-spacing: -0.03rem

.rightSection
    margin-top: 1rem
    flex: 0.45
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: 0.5rem
    z-index: 9
