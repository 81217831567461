@use 'common'

.wrapper
	&:not(.is_supported)
		visibility: hidden

.icon
	display: flex

.action
	display: inline-flex
	column-gap: 0.8em
	align-items: center
	border: none
	font-size: inherit
	color: inherit
	padding: 0
	margin: 0
	background-color: transparent

	&:hover,
	&:focus-visible
		text-decoration: underline

		.icon
			transform: scale(1.1)
