@use 'common'

.wrapper
	//

.list
	display: flex
	flex-wrap: wrap
	gap: 1.5rem
	list-style-type: none
	padding: unset
	@media (min-width: common.$break62)
		gap: 2.5rem

.item
	font-size: 1.25em
	line-height: 1.23
	font-weight: 700
	color: #808080
	transition-property: color .2s
	font-family: common.$objectSansStack
	@media (min-width: common.$break62)
		font-size: 1.625em

	&.is_active
		color: #000000

	&Link
		transition: opacity .2s

		&:hover, &:focus-visible
			opacity: .7
			outline: unset
