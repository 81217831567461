@use 'common'

.parent
    display: flex
    flex-direction: column
    gap: 1rem
    min-height: 60rem
    @media (min-width: common.$break62)
        display: grid
        grid-template-columns: 2fr 1.5fr 1.2fr 1.5fr
        grid-template-rows: 24% 4% 16% 6% repeat(2,8%) 16%

.div1
    grid-area: 1 / 1 / 4 / 2
.div2
    grid-area: 2 / 2 / 5 / 3
.div3
    grid-area: 1 / 3 / 3 / 5
.div4
    grid-area: 4 / 1 / 7 / 2
.div5
    grid-area: 5 / 2 / 8 / 4
.div6
    grid-area: 3 / 4 / 6 / 5

.svg1
    grid-area: 1 / 2 / 2 / 3
.svg2
    grid-area: 3 / 3 / 4 / 4
.svg3
    grid-area: 5 / 4 / 6 / 6

.svg1,.svg2,.svg3
    display: none
    @media(min-width: common.$break62)
        display: flex
.svg1
    padding-bottom: 1rem
    align-items: flex-end

.svg2
    align-items: center
    justify-content: center
    padding-top: 50px
.svg3
    padding-top: 80px

.div1,.div2,.div3,.div4,.div5,.div6
    border-radius: 1rem
    background: #FAFAFA
    display: flex
    padding: 2rem
    gap: 0.5rem
    flex-direction: column
    margin: unset
    position: relative
    cursor: pointer
    @media (max-width: common.$break62)
        height: 349px
        max-width: 580px

.textContainer
    position: absolute
    bottom: 5.5%
    left: 1.75rem

    @media (min-width: common.$break48)
        left: 2rem
        bottom: 1.5rem

.cardDescription
    margin: unset
    padding: unset
    font-size: 1.125rem
    line-height: 28px
    letter-spacing: -0.4px
    font-family: common.$objectSans

    @media (min-width: common.$break62)
        color: #0A0A0E
        font-size: 1.5rem
        font-family: common.$objectSans
        line-height: 2rem
        letter-spacing: -0.48px

.starAnimated
    +common.rotateHumbleAnimation

.svg2
    position: relative

.starAnimated
    position: absolute
    top: 25%

.modal
    z-index: 99999
