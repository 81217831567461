@use 'common'

.parent
    display: flex
    flex-direction: column
    gap: 1rem
    padding: 0 1rem

.div1
    grid-area: 1 / 1 / 4 / 2
.div2
    grid-area: 1 / 3 / 3 / 5
.div3
    grid-area: 2 / 2 / 5 / 3
.div4
    grid-area: 4 / 1 / 7 / 2
.div5
    grid-area: 5 / 2 / 8 / 4
.div6
    grid-area: 3 / 4 / 6 / 5

.shape1
    grid-area: 3 / 3 / 5 / 4
.shape2
    grid-area: 7 / 1 / 8 / 2
.shape3
    grid-area: 6 / 4 / 8 / 5

@media (min-width: common.$break62)
    .parent
        display: grid
        justify-content: center
        padding: 0 1.125rem 5rem 1.125rem
        grid-template-columns: 33.2% 24.9% 16.6% 24.9%
        grid-template-rows: 8.3% 24.9% 8.3% 16.6% 8.3% 4.1% 24.9%
        gap: 1rem
        width: 100%
    .shape1
        display: flex
        align-items: center
        justify-content: center
    .shape2
        display: flex
        align-items: center
        justify-content: flex-end
    .shape3
        display: flex
        align-items: center
        justify-content: start
    .shape4
        display: flex
        align-items: center
        justify-content: end

.div1,.div2,.div3,.div4,.div5,.div6
    border-radius: 1rem
    background: #FAFAFA
    display: flex
    padding: 2rem
    gap: 0.5rem
    flex-direction: column
    margin: unset

.shape1,.shape2,.shape3,.shape4
    display: none
    @media (min-width: common.$break62)
        display: flex

.cardDescription
    font-size: 1.5rem
    line-height: 2rem
    letter-spacing: -0.48px
    @media (min-width: common.$break62)
        color: #0A0A0E
        font-size: 2rem
        font-family: common.$objectSans
        margin: unset
        padding: unset
        line-height: 2.5rem
        letter-spacing: -0.64px

.cardAuthor
    font-size: 0.875rem
    color: #0A0A0E
    line-height: 22px
    font-family: common.$sourceSans3
    font-weight: 400
    margin: unset
    padding: unset

.coloredBoxContainer
    width: 100%
    border-radius: 1rem
    font-family: common.$objectSans
    position: relative
    margin: unset
    padding: unset
    padding: 2rem 2rem
    display: flex
    flex-direction: column

.percentage
    font-size: 50px
    line-height: 56px
    letter-spacing: -2px
    margin: unset
    padding: unset
    font-family: common.$objectSansStack
    @media (min-width: common.$break62)
        font-size: 4em
        line-height: 1
        letter-spacing: -3.2px

.message
    margin: unset
    padding: unset
    font-weight: 400
    max-width: 263px
    font-size: 1.25rem
    line-height: 28px
    letter-spacing: -0.4px
    font-family: common.$objectSansStack
    @media (min-width: common.$break62)
        font-size: 1.5rem
        line-height: 2rem
        letter-spacing: -0.48px
        max-width: 463px

.damnWrapper
    position: absolute
    bottom: 80%
    right: -4%
    height: 1.25rem
    width: 5rem
    @media (min-width: common.$break62)
        bottom: 15%
        right: -5%

.shoulderBrushingWrapper
    position: absolute
    bottom: 8%
    right: 3%
    height: 1.25rem
    width: 6rem
    transform: rotate(-15deg)
    @media (min-width: common.$break62)
        bottom: 6%
        right: 3%
        transform: rotate(0deg)
        bottom: 5%

.grandSlamBaby
    position: absolute
    bottom: 9%
    right: 0%
    height: 0.875rem
    width: 8rem

.starAnimated
    +common.rotateHumbleAnimation

.svg2
    position: relative

.starAnimated
    position: absolute
    top: 25%
