@use 'common'
@use 'sass:math'

.gridCardList
	display: flex
	flex-wrap: wrap
	gap: 1rem

.column
	flex-basis: 16.1875rem
	display: flex
	flex-direction: column
	gap: 1rem
	flex-grow: 1
	&:nth-child(odd)
		margin-top: 3.125rem

.gridCard
	display: flex
	flex-direction: column
	align-items: flex-start
	box-shadow: common.$box-shadow
	border-radius: common.$border-radius
	padding: 2.5rem 2rem 2.1875rem

	&.is_mobileHidden
		@media not all and (min-width: common.$break32)
			display: none

.image
	width: 75%

.quote
	font-weight: 600
	font-size: 1rem
	line-height: 1.25em
	margin: 2rem 0 1.5rem

.info
	margin: 0
	font-weight: 400
	font-size: .8125rem
	line-height: 1.25rem

.opacity
	opacity: 0.6
