@use 'common'

.wrapper
    display: flex
    flex-direction: column
    gap: 3.75rem
    padding: 0.5rem 0rem

    @media (min-width: common.$break48)
        flex-direction: row
        justify-content: space-between
        padding: 3.75rem 0rem

.leftSide
    width: 100%
    display: flex
    flex-direction: column

    @media (min-width: common.$break75)
        width: 55%

.title
    margin-block: 0
    font-size: 2rem
    line-height: 2.5rem
    letter-spacing: -0.04rem
    font-weight: normal
    font-family: common.$objectSansStack
    color: #0A0A0E

    @media (min-width: common.$break62)
        font-size: 4rem
        line-height: 4.375rem
        letter-spacing: -0.16rem

.rightSide
    width: 100%
    @media (min-width: common.$break38)
        height: 80%
    @media (min-width: common.$break75)
        width: 45%
        height: 100%
        display: flex
        justify-content: flex-end

.imageWrapper
    aspect-ratio: 4/3
    position: relative
    border-radius: 1rem
    overflow: hidden
    max-height: 27rem
    max-width: 32rem

    @media (min-width: common.$break48)
        border-radius: 3.5rem
        height: 100%
        width: 100%

.imageStyles
    width: 100%
    height: 100%
    object-fit: cover

.linkWrapper
    display: flex
    align-items: center
    gap: 0.75rem
    padding-bottom: 2.5rem

.link
    margin-block: 0
    font-size: 1.125rem
    line-height: 1.625rem
    color: #1B00FB
    font-weight: 400

.info
    margin-block: 0
    font-size: 1.125rem
    line-height: 1.625rem
    color: #3B3B3E
    font-weight: 400
    padding-top: 1.250rem
    @media (min-width: common.$break48)
        padding-top: 3.5rem
