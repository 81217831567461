@use 'common'

.wrapper
	+common.form
	row-gap: .9375rem
	position: relative
	z-index: 0

.inputs
	+common.formInputs

.note
	+common.formNote

// @TODO: custom component
.loading
	position: absolute
	display: flex
	justify-content: center
	align-items: center
	inset: -0.625rem
	z-index: 0

	&::before
		content: ''
		position: absolute
		inset: 0
		backdrop-filter: blur(.125rem)
		z-index: 0

	&Element
		display: inline-block
		position: relative
		width: 80px
		height: 80px

		&Child
			box-sizing: border-box
			display: block
			position: absolute
			width: 64px
			height: 64px
			margin: 8px
			border: 8px solid common.$blue
			border-radius: 50%
			animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
			border-color: common.$blue transparent transparent transparent

			&:nth-child(1)
				animation-delay: -0.45s

			&:nth-child(2)
				animation-delay: -0.3s

			&:nth-child(3)
				animation-delay: -0.15s

	@keyframes loading-ring
		0%
			transform: rotate(0deg)

		100%
			transform: rotate(360deg)

.afterSubmit
	position: absolute
	display: flex
	justify-content: center
	align-items: center
	padding: 0.625rem + 1.25rem
	inset: -0.625rem
	visibility: hidden
	opacity: 0
	transition-property: visiblity, opacity
	transition-duration: .3s
	z-index: 0

	&.is_active
		visibility: initial
		opacity: 1

	&::before
		content: ''
		position: absolute
		inset: 0
		backdrop-filter: blur(.125rem)
		z-index: 0

	.is_success, .is_error
		z-index: 1
