@use 'common'

.wrapper
    position: relative
    z-index: 0

    &.is_background_black,
    &.is_background_blue
        .title
            color: #FFFFFF

.main
    padding-bottom: 1rem

.title
    +common.title
    text-align: center
    font-family: common.$objectSans
    margin-top: unset
    letter-spacing: -0.18rem

    @media (min-width: common.$break62)
        line-height: 5rem

    & b
        font-family: 'caprasimo'
        font-weight: 400

.formWrapper
    display: flex
    width: 100%

.formLeftSide
    width: 50%

    @media (max-width: common.$break48)
        width: 100%

.formLeftSideContainer
    max-width: 24.25rem
    display: flex
    flex-direction: column

.formLeftSideTitle
    color: #ffffff
    font-family: common.$fustat
    font-size: 4rem
    font-style: normal
    font-weight: 500
    line-height: 4.5rem
    letter-spacing: -.03rem
    margin: unset
    padding: unset
    margin-bottom: 1.5rem

    @media (max-width: common.$break48)
        font-size: 2rem
        line-height: 3.5rem
        margin-bottom: 0rem

.formLeftSideQuote
    font-size: 1.375rem
    font-family: common.$sourceSans3
    font-weight: 400
    line-height: 30px
    margin-bottom: 1.5rem

.form
    display: flex
    justify-content: center
    width: 50%
    padding-top: 4rem
    padding-bottom: 2rem

    @media (max-width: common.$break48)
        width: 100%
        padding-top: 0rem
        padding-bottom: 0rem

.leftWrapper
    background: #0A0A0E
    color: #ffffff !important
    display: flex
    gap: 3rem
    width: 100%
    padding: 5rem 5.59rem
    margin-top: 4rem
    border-radius: 3rem

    @media (max-width: common.$break48)
        flex-direction: column
        padding: 3.5rem 2rem 4.5rem 2rem
        gap: 1rem
        border-radius: 0rem
        margin-top: 1rem

.iconWrapper
    padding-bottom: 1rem

.tileWrapper
    margin: 2rem 0
    width: 100%
    display: flex
    flex-direction: column
    gap: 1.125rem

.tileContainer
    width: 100%
    display: flex
    flex-direction: row
    gap: 1rem
    font-family: common.$sourceSans3
    font-size: 1rem
    font-style: normal
    font-weight: 400
    line-height: 1.5rem
