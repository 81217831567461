@use 'common'
@use 'sass:math'

.background
    display: flex
    padding-bottom: 0rem

    @media (min-width: common.$break48)
        padding-bottom: 0

    &.is_background_white, &.is_background_grey
        .title
            color: #000000

    &.is_background_blue, &.is_background_black
        .title
            color: #FFFFFF

.wrapper
    display: flex
    flex-direction: column
    align-items: center
    gap: 50px
    @media (min-width: common.$break62)
        gap: 0px

.title
    font-family: common.$objectSansStack
    font-weight: 400
    font-size: 2rem
    line-height: 2.5rem
    letter-spacing: -0.04rem
    text-align: center
    color: inherit
    margin-top: 3rem

    @media (min-width: common.$break38)
        font-size: 3rem
    @media (min-width: common.$break62)
        font-size: 4rem
        line-height: 4.375rem
        letter-spacing: -0.16rem

.subtitle
    margin: 1rem
    text-align: center
    max-width: 48rem

.bannerFeatures
    display: flex
    flex-wrap: wrap
    justify-content: center
    width: 100%
    column-gap: 5rem
    margin-bottom: -2rem

    @media (min-width: common.$break48)
        margin-bottom: 0
        padding: 2rem 3rem
        column-gap: 4.49rem
    @media (min-width: common.$break75)
        padding-top: 5rem

.bannerFeature
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    text-align: center
    margin-top: 0rem
    max-width: 5rem

    @media (min-width: common.$break48)
        padding-bottom: 0rem
        max-width: 6.4431rem

.checkmark
    font-size: 2em

.bannerFeatureMainTitle
    margin-top: 1.18rem
    font-size: 0.875rem
    line-height: 1.375rem
    text-align: center

    @media (min-width: common.$break48)
        margin-top: 1.47rem
        font-size: 0.875rem

.bannerFeatureTitle
    max-width: 65%
    font-family: common.$sourceSans3Stack
    font-size: 1rem
    font-weight: 400
    letter-spacing: -0.03rem

    @media (min-width: common.$break48)
        font-size: 1.125rem

.titleStyles
    margin-block: 0
    font-size: 2rem
    line-height: 2.5rem
    padding: 0rem 3rem
    @media (min-width: common.$break48)
        font-size: 3.125rem
        line-height: 3.5rem
        padding: 0
