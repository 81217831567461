@use 'common'

$border-radius-sm: 2rem
$border-radius: 3.5rem

.parent
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: auto
    grid-column-gap: 1rem
    grid-row-gap: 1rem

    p
        font-family: common.$sourceSans3Stack
        font-size: clamp(1rem, 1.1vw, 1.125rem)
        font-style: normal
        font-weight: 400
        line-height: clamp(1.5rem, 1vw, 1.625rem)

    .fiftyWidth
        width: 100%

        @media (min-width: common.$break48)
            width: 55%

    .seventyWidth
        width: 100%

        @media (min-width: common.$break48)
            width: 70%

    h4
        font-family: common.$objectSansStack
        font-size: clamp(1.25rem, 2.25vw, 2rem)
        font-style: normal
        font-weight: clamp(400, 400, 500)
        line-height: clamp(1.75rem, 2.5vw, 2.5rem)
        letter-spacing: clamp(-0.025rem, -0.03vw, -0.04rem)
        margin: 0

    @media (min-width: common.$break48)
        max-height: 52rem
        max-width: 1248px
        margin: auto

        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: 1fr 0.5fr 1fr

.div1, .div2, .div3, .div4
    min-height: 18rem
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    grid-area: auto
    border-radius: $border-radius-sm
    overflow: hidden

    @media (min-width: common.$break48)
        border-radius: $border-radius

.div1
    height: 100%
    width: 100%
    position: relative
    grid-area: auto
    background-color: #9B88FE
    color: #ffffff
    aspect-ratio: 1/1
    display: flex

    @media (min-width: common.$break48)
        aspect-ratio: auto
        flex-direction: row
        grid-area: 1 / 1 / 2 / 2

.div2
    height: 100%
    width: 100%
    position: relative
    grid-area: auto
    background-color: #FFD3EA
    aspect-ratio: 1/1

    @media (min-width: common.$break48)
        aspect-ratio: auto
        grid-area: 1 / 2 / 3 / 3

.div3
    height: 100%
    width: 100%
    position: relative
    grid-area: auto
    background-color: #F5F5F5
    padding: 0 0 0 0
    aspect-ratio: 1/1

    @media (min-width: common.$break48)
        grid-area: 2 / 1 / 4 / 2
        height: 100%
        width: 100%

.div4
    height: 100%
    width: 100%
    position: relative
    grid-area: auto
    background-color: #034062
    aspect-ratio: 1/1
    color: white !important

    @media (min-width: common.$break48)
        aspect-ratio: auto
        grid-area: 3 / 2 / 4 / 3
        flex-direction: row
        height: 100%

.textContent
    height: 100%
    width: 100%
    z-index: 10
    padding: clamp(2.5rem, 5vw, 3.5rem)
    display: flex
    flex-direction: column
    gap: .5rem

    @media (min-width: common.$break48)
        padding: 10%

.videoBackground
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1

    #video1,#video2,#video3,#video4
        position: absolute
        bottom: 0
