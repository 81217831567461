@use 'common'

.wrapper
    max-width: 90rem
    margin: auto
    height: 100%
    overflow: hidden

.content_wrapper
    position: relative
    display: grid
    min-height: 100vh
    grid-template-columns: repeat(1, minmax(0, 1fr))
    grid-template-rows: repeat(1, minmax(0, 1fr))

    @media (min-width: common.$break48)
        grid-template-columns: repeat(2, minmax(0, 1fr))

.left_side
    margin-left: 0
    position: relative
    display: grid
    grid-template-columns: repeat(1, minmax(0, 1fr))
    grid-template-rows: min-content auto min-content
    padding-left: 1.5rem
    padding-right: 1.5rem

    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    gap: 8rem

    @media (min-width: common.$break48)
        padding: 4rem 2rem
        // margin-left: 6rem
        gap: 0
        align-items: center
        justify-content: center

    .logo
        padding-top: 3rem

        span
            max-height: 1rem

        @media (min-width: common.$break48)
            padding-top: 0
            padding-bottom: 0

    .title
        +common.heading3
        position: relative
        text-align: center

        br
            // display: none

        @media (min-width: common.$break48)
            br
                display: block

    .links_section
        display: flex
        flex-direction: column
        gap: 1rem
        padding-top: 2.5rem
        padding-bottom: 5rem

        @media (min-width: common.$break48)
            padding-top: 0
            padding-bottom: 0

.right_side
    display: none
    height: 100%
    padding: 4rem 0

    @media (min-width: common.$break48)
        display: flex
        align-items: center
        justify-content: center

        padding: 4rem 2rem

    .wrapper
        width: 100%
        height: 100%
        border-radius: 1.25rem

        display: flex
        align-items: center
        justify-content: center

        @media (min-width: common.$break48)
            // padding-right: 6rem

    .title
        +common.heading2
        line-height: 1
        font-weight: 400
        position: relative
        text-align: center
        padding: 0 1rem

        span > span > svg
            height: 1em !important

.link_wrapper
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between

    gap: 1rem
    padding: 1.5rem
    border: 1.149px solid #DCDFE1
    border-radius: 0.5745rem
    box-shadow: 0px 2.298px 18.385px 0px rgba(0, 0, 0, 0.05)

    &:hover
        border: 1.149px solid #3B3B3E
        transition: .3s all ease-in-out

    .content
        width: 100%
        display: flex
        gap: .75rem
        flex-direction: column

        span, p
            margin: 0

        span
            font-family: common.$sourceSans3Stack
            font-weight: 500
            line-height: 1

        p
            color: #6C6C6E
            font-family: common.$sourceSans3Stack
            font-weight: 400
            line-height: 1

    .icon_wrapper
        display: none

        @media (min-width: common.$break48)
            display: flex
            align-items: center
            justify-content: center

=keyframes($name)
    @-webkit-keyframes #{$name}
        @content
    @-moz-keyframes #{$name}
        @content
    @-ms-keyframes #{$name}
        @content
    @keyframes #{$name}
        @content

+keyframes(spin)
  from
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)
  to
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

.gradientCircle
    width: 1000px
    height: 1000px
    border-radius: 50%
    background: radial-gradient(circle at 275px 500px, #FE5621 25%, #1B00FE 100%)
    filter: blur(60px)
    transform: translate3d(0, 0, 0)
    opacity: 0.15

.animationOnTop
    position: absolute
    top: -798px
    left: 50%
    z-index: 20
    margin-left: -564px
    height: 1128px
    width: 1128px
    -webkit-animation: spin 5s linear infinite
    -moz-animation: spin 5s linear infinite
    -o-animation: spin 5s linear infinite
    animation: spin 5s linear infinite
    transform: translateZ(0)
    -webkit-transform: translateZ(0)
    pointer-events: none
    overflow: visible
    animation-iteration-count: infinite
    -webkit-animation-iteration-count: infinite
    transform-style: flat
    -webkit-transform-style: flat
    backface-visibility: hidden
    perspective: 1000
    -webkit-perspective: 1000
    -webkit-backface-visibility: hidden

    @media (min-width: 768px)
        margin-left: -80%

    @media (min-width: 1024px)
        margin-left: -70%

    @media (min-width: 1280px)
        margin-left: -60%

    @media (min-width: 1536px)
        margin-left: -50%

.bottomWrapper
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 2rem

.bottomText
    margin-block: 0
    font-weight: 600
    color: #000
    font-size: 0.75rem
    line-height: 162.9%

.bottomLink
    margin-block: 0
    font-weight: 600
    color: #1B00FB
    font-size: 0.75rem
    line-height: 162.9%
