@use 'common'

.wrapper
    display: flex
    flex-direction: column
    width: 100%
    background-color: #FBE9E7
    border-radius: 2rem
    @media (min-width: common.$break38)
        flex-direction: row
        gap: 2rem

.leftSide
    display: flex
    flex-direction: column
    gap: 2rem
    width: 100%
    padding: 2.72rem 1.5rem
    @media (min-width: common.$break38)
        width: 50%
        padding: 2.69rem 2.6rem

.rightSide
    width: 100%
    height: 11.65rem
    position: relative
    @media (min-width: common.$break38)
        width: 50%
        height: auto

.primaryText
    font-size: 1.25rem
    font-family: common.$objectSansStack
    color: #0A0A0E
    margin-block: 0
    line-height: 1.75rem

.secondaryText
    font-size: 1.00963rem
    color: #0A0A0E
    margin-block: 0
    line-height: 1.45831rem

.buttonWrapper
    max-width: 13.3125rem

.imageStyles
    border-bottom-left-radius: 2rem
    border-bottom-right-radius: 2rem
    @media (min-width: common.$break38)
        border-bottom-left-radius: 0rem
        border-top-right-radius: 2rem
        border-bottom-right-radius: 2rem
