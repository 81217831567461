@use 'sass:math'
@use 'common'

$-border-width: 1px
$-horizontal-padding: 2.3rem
$-vertical-padding: 1.6rem
$-border-radius: 0.625em
$-break: common.$break75
$-featured-start-offset: 14%

.wrapper
	position: relative
	z-index: 0
	border: $-border-width solid #D9D9D9
	border-radius: $-border-radius
	background-color: #FFFFFF
	color: #333333
	padding: $-vertical-padding $-horizontal-padding
	display: grid
	grid-template-rows: auto 1fr auto

	&.is_featured
		@media (min-width: $-break)
			column-gap: 1em
			grid-template-columns: 1fr 1fr

.thumbnail
	position: relative
	margin: calc(-1 * ($-border-width + $-vertical-padding)) calc(-1 * ($-border-width + $-horizontal-padding)) 0
	aspect-ratio: 380 / 310

	.is_immersive &
		position: static

	&In
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		overflow: hidden
		border-top-left-radius: $-border-radius
		border-top-right-radius: $-border-radius
		background-color: #1B1B26

		.is_immersive &
			margin: -1 * $-border-width
			aspect-ratio: auto
			border-radius: $-border-radius

			&::before
				content: ''
				position: absolute
				inset: 0
				z-index: 1
				background-image: linear-gradient(180deg, rgba(#000000, 0) 0%, rgba(#000000, 0.5) 83.85%)

	@media (min-width: $-break)
		.is_featured &
			margin-left: 0
			grid-column: 2
			aspect-ratio: 600 / 480

		.is_featured:not(.is_immersive) &In
			border-top-left-radius: 0

.main
	z-index: 1

	.is_featured &
		@media (min-width: $-break)
			padding-left: $-featured-start-offset
			align-self: center
			order: -1

.title
	margin: $-vertical-padding 0 0
	font-size: 0.875em
	font-weight: 500

	.is_featured &
		@media (min-width: $-break)
			font-size: 2.6875em
			font-weight: 800
			line-height: 1.01em
			color: #000000
			margin-top: 0
			max-width: 10em

	.is_immersive &
		color: #FFFFFF

.description
	color: #8E8E8E
	font-size: 0.875em
	line-height: 1.36
	margin-top: 1.5em
	max-width: 22em

	.is_immersive &
		color: #FFFFFF

	@media not all and (min-width: $-break)
		display: none

.callToAction
	margin-top: 1.2em

	@media not all and (min-width: $-break)
		display: none

.meta
	--InspirationTile-divider-color: #D9D9D9
	position: relative
	z-index: 1
	display: flex
	gap: 2rem
	padding-top: 2em
	grid-column: 1 / -1

	.is_immersive &
		--InspirationTile-divider-color: transparent

	.is_featured &
		@media (min-width: $-break)
			padding-left: math.div($-featured-start-offset, 2)
			padding-top: $-vertical-padding

			&::before
				content: ''
				position: absolute
				top: 0
				left: -1 * $-horizontal-padding
				right: -1 * $-horizontal-padding
				border-top: $-border-width solid var(--InspirationTile-divider-color)

.author
	font-size: 0.75em
	font-weight: 500
	color: #8E8E8E
	flex-grow: 1

	.is_immersive &
		color: rgba(#FFFFFF, 0.6)

.views,
.likes
	font-size: 0.75em
	color: #1B1B26
	display: flex
	align-items: center
	gap: 0.5em
	position: relative

	.is_immersive &
		color: #FFFFFF

.views .icon
	font-size: 1.143em

.likes .icon
	font-size: 1.25em
	position: relative
	top: 0.05em

.views + .likes
	&::before
		position: absolute
		left: -1rem
		bottom: 0
		top: 0
		content: ''
		border-left: 1px solid #D9D9D9

		.is_immersive &
			border-left-color: rgba(#FFFFFF, 0.6)

.labels
	position: absolute
	top: 1.4em
	left: 0
	display: flex
	flex-direction: column
	align-items: flex-start
	gap: 0.5em

.label
	$height: 1.6em
	$vertical-padding: 0.4em
	$corner-width: 0.8em

	position: relative
	margin-left: calc(-1 * (math.div($height, 2) + $corner-width + $-border-width))
	background-color: #FB483D
	color: #FFFFFF
	border-radius: math.div($height, 2)
	font-size: 0.625em
	line-height: $height - 2 * $vertical-padding
	font-weight: 500
	padding: $vertical-padding 1em
	min-height: $height

	&::before
		content: ''
		position: absolute
		top: 100%
		left: math.div($height, 2)
		border-right: $corner-width solid #AE0000
		border-bottom: $corner-width * 0.8 solid transparent
