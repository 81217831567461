@use 'common'

.gallery
	display: flex
	flex-wrap: wrap
	justify-content: center

.image
	width: 100%
	@media (min-width: common.$break48)
		width: 50%
