@use 'common'

.wrapper
	width: 100%
	display: flex

	.main
		width: inherit
		display: flex
		justify-content: center
		flex-direction: column
		padding: 2.5rem 1.875rem 1.875rem
		border-radius: 3.5rem
		gap: 1.875em
		background-color: #F5F5F5
		color: #000000

		.text
			font-family: common.$objectSans

		@media (min-width: common.$break30)
			padding-left: 2.5rem
			padding-right: 2.5rem
			padding-top: 8.5rem
			padding-bottom: 8.5rem

			.text
				font-size: 1.5rem
				font-style: normal
				font-weight: 400
				line-height: 2rem
				letter-spacing: -0.03rem

		@media (min-width: common.$break62)
			padding: 12.3rem

			.text
				font-size: 3.125rem
				letter-spacing: -0.125rem
				line-height: 112%
				font-weight: 400

.author
	font-family: common.$sourceSans3
	font-weight: 400
