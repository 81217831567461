@use 'common'

.wrapper
    display: flex
    justify-content: center
    width: 100%
    padding: 1rem 0rem
    gap: 3rem
    position: sticky
    top: 80px
    z-index: 1000
    @media (min-width: common.$break38)
        justify-content: space-between
        padding: 0.5rem 0rem
    @media (min-width: common.$break62)
        padding: 0.7rem 0rem

.infoBox
    display: flex
    flex-direction: column

.title
    margin-block: 0
    color: #000
    font-size: 1.125rem
    font-weight: 400
    line-height: 2rem
    letter-spacing: -0.03rem
    font-family: common.$objectSansStack
    @media (min-width: common.$break62)
        font-size: 1.5rem

.readingTime
    margin-block: 0
    color: #3B3B3E
    font-size: 1rem
    font-weight: 400
    line-height: 1.625rem
    @media (min-width: common.$break62)
        font-size: 1.125rem

.socialShare
    display: flex
    align-items: flex-start
    gap: 0.5rem
