@use 'common'

.cardWrapper
    width: 100%
    display: flex
    flex-direction: column
    gap: 0.875rem

    .cardMain
        width: inherit
        display: flex
        justify-content: center
        flex-direction: column
        border-radius: 2rem
        gap: 1.875em
        max-width: 51.5rem
        color: #000000

.imageContainer
    position: relative
    aspect-ratio: 1.81/1

.image
    object-fit: cover
    border-radius: 1rem
    @media (min-width: common.$break32)
        border-radius: 2rem

.imageTitleContainer
    display: flex
    justify-content: center
    width: 100%

.imageTitle
    font-size: 0.875rem
    font-weight: 400
    line-height: 1.375rem
    margin-block: 0
    @media (min-width: common.$break62)
        font-size: 1.125rem
        line-height: 1.625rem
