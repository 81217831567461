@use 'common'

=imageShift($axis: horizontal, $direction: 1, $distance: 10)
	@media (min-width: common.$break48)
		@if $axis == horizontal
			transform: translateX(calc(1px * $direction * var(--VisualLine-offset) / $distance))
		@else if $axis == vertical
			transform: translateY(calc(-1px * $direction * var(--VisualLine-offset) / $distance))
		@else
			@error "Unknown axis #{$axis}"

.wrapper
	overflow: hidden

	&.is_option1

		.main
			@media (min-width: common.$break48)
				gap: 1.875rem
				position: relative
				aspect-ratio: 1202 / 1203
				padding-top: 3.125rem
				z-index: 0

			@media (min-width: common.$break75)
				gap: 3.75rem

		.backgroundLine
			aspect-ratio: 1202 / 1203

			@media not all and (min-width: common.$break48)
				display: flex
				flex-direction: column
				justify-content: space-between
				position: relative
				z-index: 0

			@media (min-width: common.$break48)
				position: absolute
				inset: 0
				top: 50%
				transform: translateY(-50%)
				z-index: 0

		.content
			@media (min-width: common.$break48)
				display: flex
				flex-direction: column
				justify-content: center
				align-items: center

				&In
					display: flex
					justify-content: center
					gap: 5rem

		.column

			@media (min-width: common.$break48)
				align-self: flex-end
				max-width: 20.625rem

		.title
			@media (min-width: common.$break48)
				font-size: 3.375em
				max-width: 28.125rem

				&::before
					content: ''
					background: linear-gradient(180deg, rgba(241, 242, 255, 0) 0%, #FFFFFF 50.52%, rgba(255, 255, 255, 0) 100%)
					filter: blur(24px)
					position: absolute
					inset: -1.875rem
					z-index: -1

			@media (min-width: common.$break62)
				font-size: 5em

	&.is_option2
		margin-bottom: -1 * common.$gapSmall
		.main
			@media (min-width: common.$break48)
				max-width: calc(var(--content-width-wide) + 2 * var(--global-horizontal-spacing))
				width: 100%
				margin-left: auto
				margin-right: auto
				padding-left: var(--global-horizontal-spacing)
				padding-right: var(--global-horizontal-spacing)
				aspect-ratio: 1299/682
				position: relative
				z-index: 0

		.backgroundLine

			.is_desktop
				aspect-ratio: 1299 / 682

				@media not all and (min-width: common.$break48)
					display: none

				@media (min-width: common.$break48)
					width: 100%
					height: 100%

					.content
						max-width: 35rem

			.is_mobile
				position: relative

				&.is_top
					aspect-ratio: 375 / 291

				&.is_bottom
					aspect-ratio: 375 / 329

				@media (min-width: common.$break48)
					display: none

			@media (min-width: common.$break48)
				position: absolute
				inset: 0
				z-index: 0

		.content
			margin-inline: auto
			padding-inline: 1.875rem
			font-size: 1.25em
			line-height: 1.6
			text-align: center
			background-color: transparent

			@media not all and (min-width: common.$break48)
				margin-top: -37vw

			@media (min-width: common.$break22)
				padding-inline: 2.8125rem

			@media (min-width: common.$break48)
				max-width: 35rem
				margin-top: 10vw

				&::before
					content: ""
					position: absolute
					box-shadow: 0px 0px 100px 260px rgb(255 255 255 / 80%)
					top: 50%
					left: 50%
					transform: translate(-50%, -50%)
					z-index: -1

			@media (min-width: common.$break92)
				margin-top: unset

		.title
			font-size: 3.375rem

			@media (min-width: common.$break48)
				font-size: 4.25rem
				max-width: 22.5rem
				margin-bottom: 2.25rem
				margin-inline: auto

			@media (min-width: common.$break62)
				max-width: 26.25rem
				font-size: 5.5rem

.main
	@media (min-width: common.$break48)
		display: flex
		flex-direction: column
		justify-content: center

.backgroundLine
	//

.content
	position: relative
	z-index: 1

.title
	line-height: 1.1

	@media (min-width: common.$break48)
		margin: unset
		position: relative
		z-index: 0

.column
	//

.text
	//

.button
	margin-top: 1.875rem
