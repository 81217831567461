@use 'common'
@use 'sass:math'

.wrapper
	//

.main
	padding: 4em 0
	@media (min-width: common.$break62)
		padding: 10em 0

	&.view_form
		position: relative
		z-index: 1

	&.view_form
		&:not(.is_waitlist)
			display: flex
			flex-wrap: wrap

			@media (min-width: common.$break62)
				justify-content: space-between
				flex-wrap: nowrap
				max-width: calc(var(--content-width-normal) + 2 * var(--global-horizontal-spacing))
				padding: 0 var(--global-horizontal-spacing)
				margin: 0 auto

		&.is_waitlist
			padding-block: 10.3125rem

			p
				margin-block: unset

			@media (min-width: common.$break62)
				padding-block: 11.25rem

				.title
					font-size: 3em

	&.is_background_white, &.is_background_grey
		.title
			// @TODO: color according to theme (dark/light)
			color: #000000

	&.is_background_blue, &.is_background_black
		.title
			color: inherit

	&.is_backgroundImage
		color: #FFFFFF

		.title
			color: inherit

.banner
	display: flex
	flex-direction: column
	position: relative

	&.is_initialInView
		.shape
			visibility: initial
			opacity: 1

	&.is_inView
		.shape
			&Column
				&:first-child

					.shape
						&:first-child
							+common.rotateAnimation

						&:nth-child(2)
							+common.scaleAnimation
							transition-delay: .6s
							animation-delay: .2s

						&:last-child
							+common.scaleAnimation('decreasing')
							transition-delay: .9s
							animation-delay: .2s

				&:last-child
					.shape
						&:first-child
							+common.scaleAnimation('decreasing')
							transition-delay: .9s
							animation-delay: .2s

						&:nth-child(2)
							+common.rotateAnimation
							transition-delay: .6s
							animation-delay: .2s

						&:last-child
							+common.scaleAnimation

.content
	width: 100%
	display: flex
	flex-direction: column

	&.is_center
		align-items: center
		margin-inline: auto
		text-align: center

	@media not all and (min-width: common.$break48)
		max-width: 37.5rem

.link
	position: relative
	z-index: 2

.title
	+common.title
	margin: 0
	position: relative
	z-index: 2
	padding-bottom: 2.5rem
	color: inherit

	@media (min-width: common.$break62)
		line-height: 1.1

.description
	//

.form
	display: flex
	justify-content: center

	&:not(.is_waitlist)
		padding: 0 var(--global-horizontal-spacing)

	&.is_waitlist
		width: 100%

	@media (min-width: common.$break62)
		&:not(.is_waitlist)
			width: 40%
			flex-shrink: 0

.backgroundImage
	position: absolute
	inset: 0
	z-index: 0

	&::after
		content: ''
		position: absolute
		inset: 0
		background-color: #000000
		opacity: .3
		z-index: 0

.shapes
	max-width: var(--content-width-normal)
	margin-inline: auto
	display: flex
	justify-content: space-between
	position: absolute
	inset: 0
	z-index: 0

	@media (min-width: common.$break38)
		align-items: center

.shape
	position: absolute
	display: flex
	font-size: clamp(1.5625rem, 3vw, 2.375rem)
	z-index: 0
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity
	transition-duration: .6s

	&:first-child
		color: common.$chartreuse

	&:last-child
		color: common.$chartreuse

	&Column

		&:first-child,
		&:last-child
			position: absolute
			min-width: 5.5rem

		&:first-child
			top: var(--global-horizontal-spacing)
			max-width: 13.25rem
			aspect-ratio: 212 / 345

			.shape
				&:first-child
					top: 0
					left: 50%

				&:nth-child(2)
					top: 50%
					left: 0

				&:last-child
					bottom: 0
					right: 0

		&:last-child
			bottom: var(--global-horizontal-spacing)
			aspect-ratio: 223 / 329
			max-width: 13.9375rem

			.shape
				&:first-child
					top: 0
					right: 0

				&:nth-child(2)
					top: 30%
					left: 0

				&:last-child
					bottom: 0
					left: 50%

		@media not all and (min-width: common.$break38)
			left: 50%
			transform: translateX(-50%)

		@media (min-width: common.$break38)
			&:first-child,
			&:last-child
				top: initial
				width: 18vw

			&:first-child
				left: var(--global-horizontal-spacing)

			&:last-child
				right: var(--global-horizontal-spacing)
				bottom: unset

.image
	@media (min-width: common.$break62)
		position: absolute
		inset: 0
		overflow: hidden
