@use 'common'

.mainWrapper
    position: relative
    width: 100%
    height: 100%

.imageWrapper
    max-width: calc(var(--Image-imageWrapper-maxWidth, var(--content-width-normal)) + 2 * var(--global-horizontal-spacing))
    margin: auto
    padding: 0 var(--global-horizontal-spacing)

.iconWrapper
    position: absolute
    // top: 0
    // left: 0
    max-width: 5.8rem
    max-height: 2.5rem
    // max-width: 5rem
    // max-height: 2.5rem

.iconWrapper .icon
    height: 2.5rem !important
