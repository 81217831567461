@use 'common'

$gap: 1.25rem

.wrapper
	//

.main
	padding: 0 1.5625rem
	border-radius: .9375rem

.title
	font-size: 2rem
	line-height: 2.5rem
	text-align: center
	margin: 0
	margin-bottom: 3.125rem
	font-weight: 400

.buttons
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	gap: $gap

	@media (min-width: common.$break24)
		grid-template-columns: 1fr 1fr 1fr 1fr

	@media (min-width: common.$break30)
		display: flex
		flex-wrap: wrap

.button
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100%
	background-color: #ffffff
	border-radius: .9375rem
	padding: 0
	border: unset
	outline: unset

	&:hover, &:focus-visible, &.is_active
		border: 1px solid common.$blue

.buttonWrapper
	width: 100%
	aspect-ratio: 1 / 1

	@media (min-width: common.$break24)
		max-width: 4.6875rem

.icon
	position: relative
	width: 1.5rem
	max-height: 1.5rem
	aspect-ratio: calc(var(--ProductTab-icon-width) / var(--ProductTab-icon-height))

	&.view_inContentHeader
		margin-right: .625em

.contents
	display: flex
	flex-direction: column
	margin-top: 3.125rem
	gap: 2rem
	@media (max-width: common.$break48)
		margin-top: 0rem

.content
	position: initial
	visibility: initial
	opacity: 1
	transition-duration: .3s
	transition-property: visibility, opacity
	transition-duration: 0s
	transition-timing-function: ease-out
	border-radius: 1rem

	&Header
		display: flex

	&.is_active
		position: initial
		visibility: initial
		opacity: 1
		transition-duration: .3s

.name
	margin: 0
	font-size: 1.2rem
	font-weight: normal

.description
	font-size: 1rem
	line-height: 1.5rem
	font-weight: 400
	color: #0A0A0E
	padding-right: 1.1rem

.link
	font-size: 0.875rem
	font-weight: 600
	display: flex
	border-radius: 1rem

	&_seo
		position: absolute
		overflow: hidden
		width: 0
		height: 0

	&:hover, &:focus-visible
		text-decoration: none

.textContent
	width: 60%
	padding: 2rem 0rem 2rem 2rem

.imageContainer
	margin-top: 2rem
	height: auto
	width: 40%
	position: relative
	border-bottom-right-radius: 1rem

.imageStyles
	border-bottom-right-radius: 1rem

.header
	display: flex
	gap: 10px
	justify-content: center

.iconWrapper
	width: 30px
	padding-top: 10px
