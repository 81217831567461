@use 'common'

$-break: common.$break62

.wrapper
	//

.mobile
	width: 100%
	height: auto
	display: block

	@media (min-width: $-break)
		display: none

.desktop
	@media not all and (min-width: $-break)
		display: none

.video
	aspect-ratio: 1400 / 900
	width: 100%
	height: auto
