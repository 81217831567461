@use 'common'

.wrapper
    display: flex
    flex-direction: column-reverse
    gap: 1.5rem
    padding: 0rem 2rem
    @media (min-width: common.$break48)
        flex-direction: row
        padding: 0

.tabContainer
    display: grid
    grid-template-columns: 50% 50%
    gap: 0.5rem
    @media (min-width: common.$break48)
        grid-template-columns: auto
        gap: 1rem

.tab
    display: flex
    align-items: center
    justify-content: center
    background: #F5F5F5
    border-radius: 1rem
    cursor: pointer
    width: 100%
    height: 100%
    position: relative
    min-height: 56px
    min-width: 151px
    max-height: 56px
    @media (min-width: common.$break48)
        height: 96px
        width: 290px
        max-height: 96px

    &:hover:not(.activeTab)
        background-color: #E7E7E7
        border-radius: 1rem

    &.activeTab
        background: #0A0A0E
        opacity: 1
        color: white

        .logo
            filter: invert(1)
            transition: filter 0.3s ease

.logo
    padding: 1rem 2.5rem
    object-fit: contain
    border-radius: 1rem
    width: 100%
    height: 100%
    @media (min-width: common.$break48)
        padding: 2rem 3rem

.embedContainer
    width: 100%
    border-radius: 0.5rem
    aspect-ratio: var(--Frame-iframe-aspectRatioMobile)
    max-height: 350px
    background: #D8D8D8
    border: 1px solid #D8D8D8
    @media (min-width: common.$break62)
        border-radius: 21px
        aspect-ratio: var(--Frame-iframe-aspectRatio)
        max-height: 656px

.iframe
    width: 100%
    height: 100%
    border-radius: 0.5rem
    @media (min-width: common.$break32)
        border-radius: 21px
