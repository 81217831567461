@use 'common'
@use 'sass:math'

$-image-gap: 1.875rem

.wrapper
	overflow: hidden

.hero
	display: flex
	flex-direction: column
	row-gap: 0.5em

	&.is_moreBubbles

		.title
			+common.title
			margin: 0

			@media (min-width: common.$break62)
				font-size: 2.9rem
				line-height: 1.21em

		.text
			//

		.image
			margin: 0 -40% 0 -80%
			@media (min-width: common.$break30)
				margin: 0 -30% 0 -60%
			@media (min-width: common.$break48)
				margin: 0 -10% 0 -20%
			@media (min-width: common.$break62)
				margin: 0

			&::before
				aspect-ratio: 2290 / 1286

		.in
			@media (min-width: common.$break62)
				position: absolute
				inset: 0

		@media (min-width: common.$break62)
			display: block
			position: relative
			padding: 0

	&.is_lessBubbles
		.image
			margin: 0 -20% 0 -90%

			@media (min-width: common.$break30)
				margin: 0 -20% 0 -70%
			@media (min-width: common.$break48)
				margin: 0 -10% 0 -20%
			@media (min-width: common.$break62)
				margin: 0

			&::before
				aspect-ratio: 2290 / 1000

		.background
			@media (min-width: common.$break62)
				position: absolute
				inset: 0

	&.is_video
		justify-content: space-between

		@media (min-width: common.$break48)
			flex-direction: row

			.in
				width: 40%

			.background
				width: 50%

	&.is_lessBubbles, &.is_video
		.title
			+common.title(true)
			margin: 0

			@media (min-width: common.$break62)
				font-size: 6rem
				line-height: 1em

		.text
			font-size: 1.125em
			line-height: 1.77em
			opacity: 0.73

			@media (min-width: common.$break62)
				line-height: common.$line-height
				font-size: 1.25rem

		.in
			@media (min-width: common.$break62)
				position: relative

		@media (min-width: common.$break62)
			display: flex
			flex-direction: row
			position: relative
			padding: 0

	&.is_moreBubbles,
	&.is_lessBubbles,
	&.is_dynamic
		@media (min-width: common.$break62)
			margin: 0 -1rem

.video
	width: 100%
	aspect-ratio: calc(var(--Hero-video-width) / var(--Hero-video-height))

.image
	position: relative

	&::before
		content: ''
		display: block

.in
	width: 100%
	z-index: 2

	@media (min-width: common.$break62)
		width: 40%

.text
	margin: 2rem 0 3rem

.background
	//

.rows
	position: absolute
	inset: 1rem 0 0
	display: grid
	gap: $-image-gap

.row
	display: flex
	justify-content: flex-end
	gap: $-image-gap

	&:nth-child(1)
		--Hero-distance: 2
	&:nth-child(2)
		--Hero-distance: 3
	&:nth-child(3)
		--Hero-distance: 1.5
	&:nth-child(4)
		--Hero-distance: 1.9

	@media (min-width: common.$break48)
		transform: translateX(calc(1px * var(--Hero-offset) / var(--Hero-distance)))

	&_item
		aspect-ratio: calc(var(--Hero-item-width) / var(--Hero-item-height))
		width: calc(var(--Hero-item-width) * 1px) // because #safariIsNewIE
