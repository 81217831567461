@use 'common'
    
.hover_card_wrapper
    position: relative
    text-decoration: none
    width: fit-content    
    height: min-content

    &:hover img
        opacity: 1
        transition: all 0.2s ease

    &:hover .text
        color: #FE5621 !important

    .text
        color: white !important
        font-family: common.$objectSans
        font-size: 3.125rem
        font-style: normal
        font-weight: 400
        line-height: 3.5rem
        letter-spacing: -0.125rem

       

    .img
        aspect-ratio: auto
        height: 15rem
        max-width: 26.85rem
        border-radius: 1.1rem
        transform: rotate(0deg)
        object-fit: cover
        position: absolute
        top: -50%
        left: 0
        opacity: 0
        z-index: 1

        &.imgBottom
            top: -100%
            left: 0

        