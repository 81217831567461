@use 'common'

.wrapper
	background-color: #ececec
	color: #1b1b26

.in
	display: grid
	align-items: center
	gap: 1em
	grid-template-columns: auto auto
	justify-content: space-between
	padding: 1em 0

.home
	color: inherit

	&:hover,
	&:focus
		color: #000000

.user
	font-size: 0.875em
	display: flex
	align-items: center
	gap: 0.75em
	line-height: 1.1

.avatar
	position: relative
	aspect-ratio: 1 / 1
	width: 1.6em
	border-radius: 50%
	overflow: hidden
	flex-shrink: 0
