@use '../variables'

=inputIsCompact
	padding: .8125rem 1.25rem
	border: 1px solid #DCDFE1
	border-radius: .375rem

=inputIsError
	border-color: variables.$dangerRed

=inputErrorMessage
	color: variables.$dangerRed
	line-height: 1.1

=input
	background-color: #FFFFFF
	color: #333333
	font-size: 1em
	border-radius: 0.7em
	padding: 1.4em 1.7em
	border: none
	font-weight: 400
	outline: unset
	font-family: variables.$sourceSans3

	&:focus-visible
		box-shadow: 0 0 .625rem variables.$blue

	&::placeholder
		color: #808080
