@use 'common'

.parent
    display: flex
    flex-direction: column
    gap: 1rem
    padding: 0 1rem
    margin-top: -2.5rem

    @media (min-width: common.$break38)
        margin-top: 0

.div1
    grid-area: 1 / 1 / 2 / 2
.div2
    grid-area: 1 / 4 / 2 / 6
.div3
    grid-area: 2 / 3 / 4 / 5
.div4
    grid-area: 3 / 1 / 5 / 3
.div5
    grid-area: 3 / 5 / 5 / 6

.shape1
    grid-area: 1 / 2 / 2 / 4
.shape2
    grid-area: 2 / 1 / 3 / 3
.shape3
    grid-area: 2 / 5 / 3 / 6
.shape4
    grid-area: 4 / 3 / 5 / 5

@media (min-width: common.$break62)
    .parent
        display: grid
        align-items: center
        justify-content: center
        padding: 0 1.125rem 10rem 1.125rem
        grid-template-columns: 33.2% 8.3% 16.8% 8.3% 33.2%
        grid-template-rows: repeat(2, 33.2%) 8% 25%
        gap: 1rem
        // min-height: 80vh
        width: 100%

    .shape1
        display: flex
        align-items: center
        justify-content: center
    .shape2
        display: flex
        align-items: center
        justify-content: flex-end
    .shape3
        display: flex
        align-items: center
        justify-content: start
    .shape4
        display: flex
        align-items: center
        justify-content: end

.div1,.div2,.div3,.div4,.div5,.div6
    border-radius: 1rem
    background: #FAFAFA
    display: flex
    padding: 2rem
    gap: 0.5rem
    flex-direction: column
    margin: unset

.shape1,.shape2,.shape3,.shape4
    display: none
    @media (min-width: common.$break62)
        display: flex

.cardDescription
    color: #0A0A0E
    font-size: 2rem
    font-family: common.$objectSans
    margin: unset
    padding: unset
    line-height: 2.5rem
    letter-spacing: -0.64px

.cardAuthor
    font-size: 0.875rem
    color: #0A0A0E
    line-height: 22px
    font-family: common.$sourceSans3
    font-weight: 400
    margin: unset
    padding: unset

.coloredBoxContainer
    width: 100%
    max-width: 560px
    border-radius: 1rem
    font-family: common.$objectSans
    position: relative
    margin: unset
    padding: unset
    padding: 2rem 2rem
    display: flex
    flex-direction: column

.percentage
    font-size: 50px
    line-height: 56px
    letter-spacing: -2px
    margin: unset
    padding: unset
    font-family: common.$objectSansStack
    @media (min-width: common.$break62)
        font-size: 4em
        line-height: 1
        letter-spacing: -3.2px

.message
    margin: unset
    padding: unset
    font-weight: 400
    font-size: 1.25rem
    line-height: 28px
    letter-spacing: -0.4px
    font-family: common.$objectSansStack
    @media (min-width: common.$break62)
        font-size: 1.5rem
        line-height: 2rem
        letter-spacing: -0.48px

.damnWrapper
    position: absolute
    bottom: 80%
    right: -4%
    height: 1.25rem
    width: 5rem
    @media (min-width: common.$break62)
        bottom: 15%
        right: -5%

.shoulderBrushingWrapper
    position: absolute
    bottom: 8%
    right: 3%
    height: 1.25rem
    width: 13.25rem
    transform: rotate(-15deg)
    z-index: 2
    @media (min-width: common.$break62)
        width: 13rem
        bottom: 6%
        right: 0%
        transform: rotate(-1deg)
        bottom: 5%

.starAnimated
    +common.rotateHumbleAnimation

.svg2
    position: relative

.starAnimated
    position: absolute
    top: 25%
