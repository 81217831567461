@use 'common'

$-break: common.$break48

.wrapper
	@media (min-width: $-break)
		max-width: calc(var(--content-width-normal) + 2 * var(--global-horizontal-spacing))
		padding: 0 var(--global-horizontal-spacing)
		margin: 0 auto

.in
	+common.background('light', false) // @TODO: respect theme
	padding: 2rem var(--global-horizontal-spacing)

	@media (min-width: $-break)
		padding: 6rem 7rem
		border-radius: 1.25rem

	&.is_background_black, &.is_background_blue
		.title
			color: inherit

	&.is_background_black,
	&.is_background_blue,
	&.is_background_white,
	&.is_background_grey
		.card_title
			color: #FFFFFF

.title
	margin: 0
	font-weight: 700
	font-size: 3rem
	padding-bottom: 0.5em

	@media (min-width: $-break)
		font-size: 5rem

.items
	display: grid
	gap: 1.25rem
	list-style-type: none
	padding: 0
	margin: 0

	@media (min-width: $-break)
		grid-template-columns: 1fr 1fr

.item
	display: grid

.card
	display: grid
	grid-template-rows: 1fr
	grid-template-columns: 1fr 1fr
	background-color: #2F2F32
	border-radius: 1.875rem
	padding: 2.5rem
	row-gap: 1.25rem
	column-gap: 1rem
	transition: background-color 0.2s

	&:is(a)
		&:hover,
		&:focus-visible
			background-color: #606062

	&_title
		grid-column: 1 / -1
		font-weight: 700
		font-size: 1.25rem
		line-height: 1.6em
		margin: 0

	&_linkTitle,
	&_publishedAt
		color: #ACACAD

	&_publishedAt
		grid-column: 2

.pagination
	list-style-type: none
	padding: 0
	margin: 2rem 0 0
	display: flex
	gap: 0.875rem
	justify-content: center
	flex-wrap: wrap

	@media (min-width: $-break)
		margin-top: 3.75rem

	&_item
		//

	&_link
		$size: 1.75rem
		padding: 0 0.2em
		min-height: $size
		min-width: $size
		display: flex
		align-items: center
		justify-content: center
		text-align: center
		border-radius: 8px

		.is_active &
			background-color: #FFFFFF
			color: #000000

		.is_background_white .is_active &,
		.is_background_grey .is_active &
			background-color: common.$dark
			color: #FFFFFF
