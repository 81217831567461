@use 'common'

$tableBorderColor: #EBEBEB

.wrapper
	position: relative

	> *:last-child
		&:not(.is_reference_frame, .is_reference_loginPageBlock)
			&.section.is_background_white,
			&.section.is_wysiwyg
				margin-bottom: common.$gap

	&.is_site_gemma
		--background-dark: #181E33

.section
	width: 100%

	&.is_content_default
		//

	&.timeline
		position: sticky
		top: 0px
		z-index: 100
		background-color: #fff
		width: 100vw
		border-top: 1px solid #F5F5F5
		border-bottom: 1px solid #F5F5F5

		@media (min-width: common.$break38)
			border-top: 1px solid #E7E7E7
			border-bottom: 1px solid #E7E7E7

	&.showTimeline
		top: 0px

	&.is_content_narrow
		// max-width: calc(var(--content-width-small) + 2 * var(--global-horizontal-spacing))
		max-width: 55.5rem // 51.5rem = 824px
		margin-inline: auto

	&.is_content_wide
		max-width: calc(var(--content-width-wide) + 2 * var(--global-horizontal-spacing))

	&.is_images_carousel
		max-width: 824px

	&.is_wysiwyg
		// display: none
		&.is_demo_req_page:has(h4 > span[data-contember-type="scrollTarget"])
			display: none

	.is_theme_light &
		+common.background('light')

	.is_theme_dark &
		+common.background('dark')

	&.is_background_white + &,
	& + &.is_background_white,
	&:not(.is_wysiwyg) + &.is_wysiwyg
		margin-top: common.$gap

		&.is_reference_jumbotron
			&.is_demo_req_page
				margin-top: 0

		&.is_reference_bannerTestimonials
			margin-top: common.$gap

			@media (max-width: 998px)
				margin-top: -.01rem

		&.is_reference_asideContent
			// margin-top: -5rem
			margin-top: 0rem

			@media (min-width: 607px)
				margin-top: common.$gap

	&.is_background_white + &,
	& + &.is_background_white,
	&:not(.is_wysiwyg) + &.is_wysiwyg + &.is_imageHoverReveal
		// margin-top: 0

	// thanks to the special page "Gemma" we need to hide some sections on mobile
	$sectionsHiddenOnMobile: ".is_reference_tabsWithMedia, .is_reference_contentMedia"

	$referencesWithSmallGapsBeforeWysiwyg: ".is_reference_codeSnippet, .is_reference_button, .is_reference_image, .is_reference_table, .is_reference_genieDemoAnimation"
	$referencesWithSmallGapsAfterWysiwyg: "#{$referencesWithSmallGapsBeforeWysiwyg}, .is_reference_gridCardList, .is_reference_extensionGridCardList, .is_reference_timeline, .is_reference_revealableMediaContent"
	$referencesWithButtonWithSmallGaps: ".is_reference_table"
	$referencesWithImageWithSmallGaps: ".is_reference_jumbotron, .is_reference_stripWithLogos"
	$referencesWithStripWithLogosWithSmallGaps: ".is_reference_jumbotron"
	$referencesImageLike: ".is_reference_image, .is_reference_genieDemoAnimation"
	&.section
		&.is_wysiwyg + &:is(#{$referencesWithSmallGapsAfterWysiwyg}),
		&:is(#{$referencesWithSmallGapsBeforeWysiwyg}) + &.is_wysiwyg,
		&:is(#{$referencesWithButtonWithSmallGaps}) + &.is_reference_button,
		&:is(#{$referencesWithImageWithSmallGaps}) + &:is(#{$referencesImageLike}),
		&:is(#{$referencesWithStripWithLogosWithSmallGaps}) + &.is_reference_stripWithLogos
			margin-top: common.$gapSmall

	$referencesWithMediumGapsAfterWysiwyg: ".is_reference_gridBoxList"
	&.section
		&.is_wysiwyg + &:is(#{$referencesWithMediumGapsAfterWysiwyg})
			margin-top: common.$gapMedium

	&.section
		&:is(#{$sectionsHiddenOnMobile})
			@media not all and (min-width: common.$break62)
				display: none

	&:not(.is_background_white)
		&:not(.is_reference_banner)
			&:not(.is_reference_requestDemo)
				&:not(.is_reference_table)
					&:not(.is_wysiwyg)
						// padding-top: common.$gap
						padding-bottom: common.$gap
						&:is(.is_reference_carouselWithProducts)
							padding-bottom: 5rem !important

			.section:not(.is_background_blue) + &.is_background_blue,
			.section:not(.is_background_black) + &.is_background_black,
			.section:not(.is_background_grey) + &.is_background_grey

				&:not(.is_reference_box, .is_reference_asideContent, .is_reference_requestDemo, .is_reference_stripWithLogos, .is_reference_tabsWithMedia)
					padding-bottom: common.$gapSmall

				&:not(.is_reference_requestDemo)
					padding-top: common.$gap

	[data-contember-type="table"]
					border: 2px solid $tableBorderColor
					border-spacing: 0
					border-collapse: collapse
					margin: auto

	[data-contember-type="tableRow"]
		//

	[data-contember-type="tableCell"]
					padding: .9375em
					border: 2px solid $tableBorderColor

	[data-contember-type="scrollTarget"]
					scroll-margin-top: 9.375rem

.notImplemented
	border: 1px solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize

.is_wysiwyg
