@use 'common'

.wrapper
	//

.items
	display: grid
	gap: 2.8em 3.75em

	@media (min-width: common.$break92)
		grid-template-columns: 1fr 1fr

.name
	font-size: 1.125em
	margin: 1.8em 0
	font-weight: 500
	color: #000000
	line-height: 1.7em

.item
	//
