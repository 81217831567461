@use 'common'

.wrapper
	text-align: center

.title
	+common.heading3

.text
	opacity: var(--text-opacity)
	margin-bottom: unset

.image
	max-width: 100%
	height: auto
	margin-top: 5rem
