@use 'common'

$-base-color: #b5b5b5

.wrapper
	position: relative
	z-index: 0
	color: $-base-color // Fallback for Firefox not supporting color-mix
	color: color-mix(in srgb, $-base-color calc(100% * (1 - var(--GemmaFrame-background-progress))), #ffffff)
	aspect-ratio: 1180 / 696
	padding: 4.9% 0.04% 0.08% 4.2%
	display: flex

	&::before
		content: ''
		position: absolute
		inset: 0
		background-image: url('../images/gemma/frame.svg')
		background-size: 100%
		background-repeat: no-repeat

.in
	position: relative
	display: flex
	width: 100%

	&::before
		content: ''
		position: absolute
		z-index: -1
		inset: 0
		background-image: linear-gradient(109.92deg, #B7373B 5.31%, #B7373B 57.11%, #962e31 96.49%)
		border-bottom-right-radius: 1.3% 2.4%
		opacity: var(--GemmaFrame-background-progress)
