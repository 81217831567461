@use 'common'

.wrapper
    // margin-top: -7rem
    display: flex
    flex-direction: column
    background-color: #C5E7FF
    padding: 3rem 2rem
    overflow: hidden

    @media (min-width: common.$break62)
        flex-direction: row
        padding: 9rem 6rem

.leftSection
    flex: 0.55
    position: relative

    .title
        +common.title
        text-align: left !important
        font-weight: 400
        text-align: center
        margin: 2rem 0 0
        width: 70%

        @media (min-width: common.$break62)
            width: 100%

    .text
        font-family: common.$objectSansStack
        max-width: fit-content
        text-align: left
        margin: 2rem 0
        font-size: 1.25rem
        line-height: 1.77em
        color: #4D4D4D
        padding-right: 4rem

        @media (min-width: common.$break62)
            padding-right: 0rem
            max-width: 31.825rem
            line-height: 2rem
            font-size: 1.5rem
            letter-spacing: -0.03rem

.rightSection
    flex: 0.45

    .text
        font-family: common.$objectSansStack
        position: relative
        max-width: 31.825rem
        text-align: left
        margin: 2rem 0
        font-size: 2rem
        line-height: 1.77em
        color: white
        user-select: none

        &:hover
            color: orange
            cursor: pointer

        @media (min-width: common.$break62)
            color: var(--neutral-white, white)
            leading-trim: both

            text-edge: cap
            /* Website/H3 */
            font-family: common.$objectSansStack
            font-size: 3.125rem
            font-style: normal
            font-weight: 400
            line-height: 3.5rem
            letter-spacing: -0.125rem

    .imageContainer
        width: 100%
        max-height: 0
        overflow: hidden

        &.is_opened
            max-height: 100%

            img
                opacity: 1

        img
            border-radius: 1.10256rem
            width: 100%
            height: auto
            opacity: 0
            transition: opacity 0.5s ease-in-out

    .img_cont
        position: absolute
        width: 300px
        opacity: 0
        transition: 0.6s

        &.is_visible
            opacity: 1

        img
            border: 1px solid black
            width: 100%
            border-radius: 15px
