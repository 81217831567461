@use 'common'
@use 'sass:math'

$-break: common.$break75

.wrapper
	position: relative
	z-index: 0
	pointer-events: none

.mobile
	width: 100%
	height: auto
	display: block
	pointer-events: auto

	@media (min-width: $-break)
		display: none

.desktop
	position: relative
	z-index: 0

	@media not all and (min-width: $-break)
		display: none

.in
	position: relative
	z-index: 0

.content,
.chat
	position: sticky
	top: 0
	min-height: 100vh

.chat
	min-height: 100lvh

.content
	display: flex
	flex-direction: column
	justify-content: center
	row-gap: 2rem

.frame
	$maximum-width: 78rem
	font-size: min(#{math.div($maximum-width, 100)}, 0.8vw, 1.2vh)
	width: 100em
	margin-inline: auto

	&_content
		position: relative
		z-index: 1
		width: 100%
		pointer-events: auto

.chat
	$large-chat-height-estimate: 53rem
	margin-top: calc(-50vh + #{math.div($large-chat-height-estimate, 2)})
	z-index: 1
	overflow: hidden
	display: flex
	opacity: max(0, 1 - 2 * var(--GemmaDemoAnimation-background-progress))

	&_in
		min-height: 100%
		display: grid
		justify-items: center
		align-items: center
		transform: translateX(calc(50% * var(--GemmaDemoAnimation-chat-slideToSideProgress)))

	&_content
		--top: max(-10rem, 22rem - 50vh)
		--extra-scale: 0.05
		pointer-events: auto
		max-width: common.$gemma-chat-width
		width: 100%
		transform: translateX(calc(-50% * var(--GemmaDemoAnimation-chat-slideToSideProgress))) translateY(calc(var(--top) * var(--GemmaDemoAnimation-chat-slideToSideProgress))) scale(calc(1 + var(--extra-scale) * (1 - var(--GemmaDemoAnimation-chat-slideToSideProgress))))

.timeline
	margin-top: -100vh
	height: var(--GemmaDemoAnimation-timeline-height)

.cursor
	position: absolute
	z-index: 1
	top: 0
	left: 0
	transform: translate(var(--GemmaDemoAnimation-cursorPosition-x), var(--GemmaDemoAnimation-cursorPosition-y))
	opacity: 0
	transition-property: opacity
	transition-duration: 0.2s

	&.is_active
		opacity: 1

.catchPhrase
	pointer-events: auto
	position: relative
	top: clamp(-8rem, 10rem - 20vh, 0rem)
