@use 'common'

.parent
    display: grid
    // grid-template-columns: 33.3% 25% 12% 25%
    grid-template-columns: 2fr 1.5fr 1.2fr 1.5fr
    // grid-template-rows: 25% 8% 20% 4% repeat(2, 8%)
    grid-template-rows: 24% 4% 16% 6% repeat(2,8%)
    grid-column-gap: 16px
    grid-row-gap: 16px
    min-height: 840px

.div1
    grid-area: 1 / 1 / 4 / 2
.div2
    grid-area: 2 / 2 / 5 / 3
.div3
    grid-area: 1 / 3 / 3 / 5
.div4
    grid-area: 4 / 1 / 7 / 2
.div5
    grid-area: 5 / 2 / 8 / 4
.div6
    grid-area: 3 / 4 / 6 / 5

.div1,.div2,.div3,.div4,.div5,.div6
    border-radius: 1rem
    background: #FAFAFA
    display: flex
    padding: 2rem
    gap: 0.5rem
    flex-direction: column
    margin: unset

.cardDescription
    color: #0A0A0E
    font-size: 2rem
    font-family: common.$objectSans
    margin: unset
    padding: unset
    line-height: 2.5rem
    letter-spacing: -0.64px

.cardAuthor
    font-size: 0.875rem
    color: #0A0A0E
    line-height: 22px
    font-family: common.$sourceSans3
    font-weight: 400
    margin: unset
    padding: unset

.coloredBoxContainer
    width: 100%
    border-radius: 1rem
    font-family: common.$objectSans
    position: relative
    margin: unset
    padding: unset
    padding: 2rem 2rem
    display: flex
    flex-direction: column

.percentage
    font-size: 4em
    line-height: 1
    letter-spacing: -3.2px
    margin: unset
    padding: unset
    font-family: common.$objectSansStack

.message
    margin: unset
    padding: unset
    font-weight: 400
    font-size: 1.25rem
    line-height: 2rem
    letter-spacing: -0.48px
    font-family: common.$objectSansStack

.damnWrapper
    position: absolute
    bottom: 18%
    right: -5%
    height: 1.25rem
    width: 5rem

.shoulderBrushingWrapper
    position: absolute
    bottom: 6%
    right: 3%
    height: 1.25rem
    width: 10rem

.starAnimated
    +common.rotateHumbleAnimation

.svg2
    position: relative

.starAnimated
    position: absolute
    top: 25%
