@use 'common'

.wrapper
	background-color: #1b1b26
	color: #ffffff
	padding: 2em var(--global-horizontal-spacing)
	display: grid
	gap: 2em
	grid-template-rows: 1fr auto

.links
	//

.item
	//

.logo
	font-size: 1.875em
	text-align: center
