@use 'common'

.wrapper
	--CarouselCards-cards-gap: .625em
	--CarouselCards-sideSpacing-width: calc(var(--global-horizontal-spacing) - var(--CarouselCards-cards-gap))
	--CarouselCards-minMainContentVisible-width: 85%

	&.view_gap_large
		@media (min-width: common.$break62)
			--CarouselCards-cards-gap: 40px

	@media (min-width: common.$break30)
		--CarouselCards-cards-gap: 1.25em

	@media (min-width: common.$contentWidth-normal)
		--CarouselCards-sideSpacing-width: calc((100% - var(--content-width-normal)) / 2 - var(--CarouselCards-cards-gap))

.main
	position: relative
	z-index: 0

	&In
		position: relative
		display: grid
		z-index: 0

		// &.view_sideShadow
		// 	--CarouselCards-shadow-width: calc(2 * var(--global-horizontal-spacing) + var(--CarouselCards-sideSpacing-width))
		// 	$mainContentWidth: calc((min(var(--CarouselCards-minMainContentVisible-width), var(--content-width-normal)) + var(--CarouselCards-cards-gap)) / 2)
		// 	$startShadowPoint: calc(50% - var(--CarouselCards-shadow-width) - $mainContentWidth)
		// 	$startContentPoint: calc(50% - $mainContentWidth)
		// 	$endShadowPoint: calc(50% + var(--CarouselCards-shadow-width) + $mainContentWidth)
		// 	$endContentPoint: calc(50% + $mainContentWidth)

		// 	mask-image: linear-gradient(90deg, transparent $startShadowPoint, #ffffff $startContentPoint, #ffffff $endContentPoint, transparent $endShadowPoint)

		// 	// for debuging purpose with mask-image
		// 	// &::before
		// 		// content: ''
		// 		// position: absolute
		// 		// inset: 0
		// 		// background-image: linear-gradient(90deg, transparent $startShadowPoint, black $startContentPoint, black $endContentPoint, transparent $endShadowPoint)

		// 	@media (min-width: common.$contentWidth-normal)
		// 		--CarouselCards-shadow-width: 20rem

		&.view_slidesPerView
			&_1
				@media not all and (min-width: common.$contentWidth-normal)
					--CarouselCards-shadow-width: calc(2 * var(--page-horizontal-spacing) + var(--CarouselCards-sideSpacing-width) + 2 * var(--CarouselCards-cards-gap))

				.card
					--CarouselCards-minMainContentVisible-width: 80%
					width: var(--CarouselCards-minMainContentVisible-width)
					max-width: common.$contentWidth-wide
					flex-grow: 1

.navigation
	@media not all and (min-width: common.$break30)
		display: none

.title
	font-size: 3em
	line-height: 1.2
	font-weight: 400
	text-align: center
	margin-top: 0
	margin-bottom: (5rem - common.$cardsVerticalPadding)

.cards
	+common.hideHorizontalScrollbar
	display: flex
	gap: var(--CarouselCards-cards-gap)
	flex-wrap: nowrap
	padding-top: common.$cardsVerticalPadding
	padding-bottom: common.$cardsVerticalPadding
	scroll-snap-type: x mandatory

	&::-webkit-scrollbar
		display: none

	&SideSpace
		flex-shrink: 0
		min-width: var(--CarouselCards-sideSpacing-width)

	&.is_aligned_center
		@media (min-width: common.$break75)
			justify-content: center

			.cardsSideSpace
				display: none

.card
	display: flex
	min-width: 13.75rem
	flex-shrink: 0
	scroll-snap-align: center

.carousel_nav_wrapper
	width: 100%
	flex: 1
	display: flex
	gap: 1rem
	align-items: center
	justify-content: center

	span
		border-radius: 100%
		width: 0.75rem
		height: 0.75rem
		background-color: #1B00FB
		opacity: 0.3

		&.color
			background-color: #9D9D9F

	&.nav_wrapper_blog
		span
			background-color: #1B00FB

.iconWrapper
	width: 40px

.header
	display: flex
	align-items: center
	justify-content: center
	gap: 10px
