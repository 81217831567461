@use 'common'

.pricingAccordionContainer
    display: flex
    align-items: center
    gap: 20px
    cursor: pointer
    justify-content: space-between

.accordionTitle
    font-size: 1.125rem
    font-weight: 400
    line-height: normal
    margin-block: 0
    padding-bottom: 0.87rem
    color: #000

.arrowStyles
    padding-bottom: 0.87rem

.accordionItem
    padding-bottom: 2.44rem
    padding-top: 1.63rem

.accordionDescription
    color: #000
    margin-block: 0
    font-family: common.$sourceSans3
    font-size: 1rem
    font-style: normal
    font-weight: 400
    line-height: normal
