@use 'common'

.wrapper
	position: relative
	width: fit-content
	margin-inline: auto
	text-align: center
	z-index: 0

	&.is_initialInView
		.image
			&Item
				visibility: initial
				opacity: 1

	&.is_inView

		.image
			&Item

				&:first-child
					.imageItemIn
						+common.rotateAnimation

				&:nth-child(2)
					.imageItemIn
						+common.scaleAnimation('increasing')
						transition-delay: .6s
						animation-delay: .2s

				&:nth-child(3)
					.imageItemIn
						+common.scaleAnimation('decreasing')
						transition-delay: .9s
						animation-delay: .2s

.text
	font-size: 1.875rem
	line-height: 1.22em
	font-weight: 700
	font-family: common.$objectSansStack
	letter-spacing: common.$letter-spacing
	color: #000000

	@media (min-width: common.$break24)
		font-size: 2.1875rem

	@media (min-width: common.$break30)
		font-size: 2.875rem

	@media (min-width: common.$break48)
		font-size: 5rem
		line-height: 1.05

	@media (min-width: common.$break62)
		font-size: 6.25rem

.image
	width: 40%
	height: auto

	&List
		position: absolute
		inset: 0
		z-index: 0

	&Item
		position: absolute
		left: var(--FancyTitle-imageItem-locationX)
		z-index: 0
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: .6s

		&In
			display: flex
			justify-content: center
			align-items: center

		&.is_verticalLocation_top
			bottom: 100%
			transform: translateY(50%)

		&.is_verticalLocation_bottom
			top: 100%
			transform: translateY(-50%)

	@media (min-width: common.$break22)
		width: 50%

	@media (min-width: common.$break30)
		width: 60%

	@media (min-width: common.$break48)
		width: auto
