@use 'common'
@use 'sass:math'

.wrapper
	height: 100%
	padding-top: calc(var(--CardWithIcon-icon-size) / 2)

	&.iconNextToTitle
		padding-top: inherit

		.icon
			position: initial
			transform: initial

		.iconAndTitle
			display: flex
			align-items: flex-end
			gap: .625rem
			margin-bottom: 1.25rem

		.title
			margin: unset
			line-height: 0.88
			color: common.$blue

			@media (min-width: common.$break48)
				font-size: 2.25em

		@media (min-width: common.$break48)
			.main
				&In
					padding: 3.125rem 2.5rem

.main
	height: 100%
	position: relative
	display: flex
	flex-direction: column
	z-index: 0
	color: #000000

	&In
		height: 100%
		display: flex
		flex-direction: column
		padding: 2.25rem 1.875rem

		&.has_footer
			padding-bottom: 1.25rem

		@media (min-width: common.$break48)
			padding-block: 3.75rem

.icon
	position: absolute
	bottom: 100%
	font-size: 2.5rem
	transform: translateY(50%)
	z-index: 0

	&.is_mobile
		@media (min-width: common.$break38)
			display: none

	&.is_desktop
		@media not all and (min-width: common.$break38)
			display: none

.header
	margin-bottom: 1.875rem
	line-height: 1

	&.is_percentage
		color: #62ae00
		font-weight: 700
		font-size: 3.125rem
		font-family: common.$objectSansStack
		letter-spacing: common.$letter-spacing

	&.is_stars
		//

.stars
	display: flex
	color: #ffdc1d

.star
	font-size: 1.875rem

	@media (min-width: common.$break62)
		font-size: 2.5rem

.title
	font-size: 1.875em
	line-height: 1.2
	margin-block: 0 1.25rem

.body
	font-size: 1.25em
	line-height: 1.5

	&Text
		font-size: 1rem

.link

	&List
		list-style-type: none
		padding: unset
		margin-block: 1.875rem 0

	&Item
		&:not(:first-child)
			margin-top: .625rem

	&In
		font-size: 1.125em
		font-weight: 600
		color: #1B22FA

.footer
	margin-top: auto

	&Text
		display: block
		margin-top: 3.75rem
		color: common.$textColor
		font-size: .875em
