@use 'common'

.wrapper
	//
	margin: auto
	// max-width: 1100px

.main
	//

.list
	--LogoList-list-defaultMaxColumns: 2

	display: grid
	justify-items: center
	row-gap: 2.5rem
	grid-template-columns: repeat(min(var(--LogoList-list-defaultMaxColumns), var(--LogoList-list-maxColumns)), 1fr)

	@media (min-width: common.$break22)
		--LogoList-list-defaultMaxColumns: 3

	@media (min-width: common.$break30)
		--LogoList-list-defaultMaxColumns: 4

	@media (min-width: common.$break48)
		--LogoList-list-defaultMaxColumns: 7

	@media (min-width: common.$break62)
		grid-template-columns: repeat(var(--LogoList-list-maxColumns, var(--LogoList-list-defaultMaxColumns)), 1fr)

	@media (min-width: common.$break75)
		row-gap: 3.75rem

		.item
			max-width: initial

	&.is_default
		//

	&.is_box
		//

	&.is_bannerLogos
		align-items: center

	&.is_strip

		.item
			&:not(.is_withDescription)
				width: 80%
				align-self: center

				.figure
					aspect-ratio: calc(var(--LogoList-item-width) / var(--LogoList-item-height) * 1.25)
					@media (min-width: common.$break38)
						aspect-ratio: calc(var(--LogoList-item-width) / var(--LogoList-item-height))

			&.is_withDescription
				.figure
					@media (min-width: common.$break22)
						padding-inline: 1.25rem

					&In
						aspect-ratio: calc(var(--LogoList-item-width) / var(--LogoList-item-height))
						width: 1em
						position: relative

		@media (min-width: common.$break22)
			display: flex
			justify-content: center
			column-gap: 2.5rem
			flex-wrap: wrap

			.item:not(.is_withDescription)
				width: 30%
				justify-content: center

			.figure
				margin: auto
				max-height: 2.1875rem

		@media (min-width: common.$break24)
			column-gap: 3vw

		@media (min-width: common.$break48)
			justify-content: space-between

		@media (min-width: common.$break62)
			gap: 1.25rem
			flex-wrap: nowrap

			.item:not(.is_withDescription)
				aspect-ratio: calc(var(--LogoList-item-width) / var(--LogoList-item-height))
				width: initial
				align-self: initial

		@media (min-width: common.$break62)
			height: 2.875rem

	&.is_labelNextToIcon
		justify-content: center

		.figure
			flex-direction: row
			align-items: center
			gap: 1.25rem

			&Caption
				text-align: initial

.item
	position: relative

.figure
	display: flex
	align-items: center
	flex-direction: column
	margin: unset
	position: relative
	z-index: 0

	&Caption
		text-align: center
