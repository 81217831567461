@use 'common'

.wrapper
    display: flex
    flex-direction: column
    width: 100%
    height: 100%

.main
    max-width: 25rem
    height: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: 2.41rem
    color: #000000

.content
    padding: 0 2.5rem

.icon
    $maxHeight: 1.725rem
    aspect-ratio: calc(var(--ProductCard-icon-width) / var(--ProductCard-icon-height))
    position: relative
    width: calc(var(--ProductCard-icon-width) / var(--ProductCard-icon-height) * $maxHeight)
    max-height: $maxHeight
    margin-bottom: 0.725rem

.name
    margin: 0
    font-weight: normal
    font-size: 1.3rem

.description
    color: #0A0A0E
    font-size: 1.00963rem
    font-weight: 400
    margin-block: 0
    padding-top: 1.3rem

.link
    font-size: 1.125em
    font-weight: 600
    display: flex
    flex-direction: column
    justify-content: space-between
    color: common.$blue
    height: 100%

    &_seo
        display: none

.imageContainer
    position: relative
    height: 15rem
    margin-left: 2.5rem
