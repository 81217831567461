@use 'common'

$background: url('../images/interactiveBlock/last-bg.png')

.grid
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    width: 100%
    margin-left: auto
    margin-right: auto
    margin-bottom: 5rem

    @media (min-width: common.$break48)
        margin-bottom: 10rem

.row
    display: flex
    width: 100%
    gap: .5rem

    flex-wrap: wrap

    @media (min-width: common.$break48)
        padding: 0
        margin: 0
        grid-gap: clamp(1px,1.0763888889vw,15.5px)

.card
    position: relative
    text-decoration: none
    width: 100%
    overflow: hidden
    border-radius: 2rem

    @media (min-width: common.$break48)
        display: flex
        flex-direction: column
        width: calc(66% - 8px)
        justify-content: space-between

.cardContainer
    position: relative
    // background: #f5f5f5
    border-radius: 2rem
    padding: 1.5rem
    display: flex
    flex-direction: column
    aspect-ratio: 1/1

    @media (min-width: common.$break48)
        aspect-ratio: auto
        height: 100%
        padding: clamp(1px,3.0833333333vw,3.5rem)
        justify-content: space-between

.video
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

.cardOverline
    display: block
    font-family: common.$sourceSans3Stack
    font-size: clamp(1.125rem, 1.5vw, 1.375rem)
    font-style: normal
    font-weight: 400
    line-height: 1.875rem

.oneCol
    border-radius: 2rem

    @media (min-width: common.$break48)
        width: calc(33% + 3px)
        aspect-ratio: 1/1

.cardContentContainer
    display: flex
    flex-direction: column-reverse

    @media (min-width: common.$break48)
        flex-direction: row

        &.oneCol
            flex-direction: column-reverse

.cardImageContainer
    align-self: center
    padding-bottom: clamp(1px,2.0833333333vw,3.5rem)
    transition: transform .2s ease

    @media (min-width: common.$break48)
        padding-bottom: 0

        &.oneCol
            align-self: center
            margin-bottom: clamp(1px,1.25vw,18px)

.maxWidth
    //
    @media (min-width: common.$break48)
        max-width: 50%

.maxWidth70
    //
    @media (min-width: common.$break48)
        max-width: 70%

.isPaddingReset
    overflow: hidden
    padding-left: clamp(1px,3.0833333333vw,3.5rem)
    padding-top: clamp(1px,3.0833333333vw,3.5rem)
    padding-bottom: 0
    padding-right: 0

.zeroPadding
    overflow: hidden
    padding: 0

.textContentPadding
    padding: 1.5rem 1.5rem 0 1.5rem

    @media (min-width: common.$break48)
        padding-top: clamp(1px,3.0833333333vw,3.5rem)
        padding-left: clamp(1px,3.0833333333vw,3.5rem)
        padding-right: clamp(1px,3.0833333333vw,3.5rem)

.videoBackground
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1

    #video1,#video2,#video3,#video4
        position: absolute
        bottom: 0
