@use 'common'

.wrapper
	+common.input

	&.is_backgroundColor_transparent
		background-color: transparent

	&.is_color_white
		border-color: white
		color: white

	&.is_placeholder_white
		&::placeholder
			color: inherit

	&.view_compact
		+common.inputIsCompact

	&.is_highlighted
		+common.inputIsHighlighted
