@use 'common'

.wrapper
	--Inspirations-min-column-width: 18em
	margin: 0
	padding: 0
	list-style-type: none
	display: grid
	gap: 3em
	grid-template-columns: repeat(auto-fill, minmax(var(--Inspirations-min-column-width), 1fr))

	@media (min-width: common.$break38)
		--Inspirations-min-column-width: 24em

.item
	display: grid

	&.is_featured
		grid-column: 1 / -1
