@use 'common'
@use 'sass:math'

.keywordsTileBackground
	overflow: hidden

.keywordsTile
	display: flex
	flex-direction: column
	@media (min-width: common.$break30)
		align-items: center
	@media (min-width: common.$break62)
		align-items: flex-start
	@media (min-width: common.$break75)

.wrapper
	display: flex
	flex-direction: column
	@media (min-width: common.$break48)
		flex-direction: row
		width: 100%
		justify-content: space-between
.keywordContainer
	display: flex
	flex-direction: column
	align-items: flex-start
	width: 100%
	@media (min-width: common.$break62)
		width: 50%

.keywordWrapper
	--KeywordsTile-distance: 1.5
	display: flex
	align-items: center
	justify-content: center
	justify-content: flex-start
	width: 100%
	@media (min-width: common.$break30)
		width: auto
	@media (min-width: common.$break48)
		width: 100%

.keywordContainer .keywordWrapper
	--KeywordsTile-distance: 1

.keywordContainer > .keyword
	--KeywordsTile-distance: 3

.object,
.keyword
	border-radius: 4em
	width: 4.25rem
	min-height: 4.25rem
	font-family: common.$objectSansStack
	letter-spacing: common.$letter-spacing
	@media (min-width: common.$break30)
		width: 5.5rem
		min-height: 5.5rem
	@media (min-width: common.$break62)
		min-width: 8rem
		min-height: 8rem
		transform: translateX(calc(1px * var(--KeywordsTile-offset) / var(--KeywordsTile-distance)))

.is_color_black
	--KeywordsTile-color: #0A0A0E

.is_color_blue
	--KeywordsTile-color: #{common.$blue}

.is_color_red
	--KeywordsTile-color: #F20F0F

.is_color_black, .is_color_blue, .is_color_red
	background-color: var(--KeywordsTile-color)
	border: 2px solid var(--KeywordsTile-color)

.keyword
	font-size: 2em
	line-height: 1em
	display: flex
	justify-content: center
	align-items: center
	width: fit-content
	margin: 0
	color: #ffffff
	padding: 0 2rem
	margin: 0.375rem
	font-weight: 700
	padding-top: 0.5rem
	@media (min-width: common.$break30)
		font-size: 3.25rem
	@media (min-width: common.$break62)
		margin: 0.75rem
		padding: 1.5rem 4rem 1rem
		font-size: 4em
	@media (min-width: common.$break75)
		font-size: 5em

.is_wide
	max-width: 23em
	@media (min-width: common.$break30)
		min-width: 23em

.is_device_mobile
	display: none
	@media (min-width: common.$break48)
		display: flex

.is_device_tablet
	display: none
	@media (min-width: common.$break75)
		display: flex

.object
	background-color: transparent
	margin: 0.375em
	@media (min-width: common.$break62)
		margin: 0.75em

.textWrapper
	//

.text
	color: common.$textColor
	line-height: common.$line-height
	margin-bottom: 2.5em
	padding: 0 1rem

.link
	margin-left: 1rem
