@use 'common'

.wrapper
	position: relative
	display: flex
	flex-direction: column
	align-items: center
	overflow: hidden
	padding-bottom: 1rem

	&.is_gemma
		margin-top: 1.25rem

		.text
			max-width: 50.625rem
			font-size: 1rem

	@media (min-width: common.$break48)
		padding-top: 3.5rem

		&.is_gemma
			.title
				font-size: 5rem

	@media (min-width: common.$break62)

		&.is_gemma
			.title
				font-size: 6.25rem

.title
	+common.title
	font-weight: 400
	text-align: center
	margin: 2.5rem 0 0

	&.heading2
		+common.heading2

.text
	max-width: 40rem
	text-align: center
	margin: 2rem 0
	font-size: 1.125em
	line-height: 1.77em
	color: #4D4D4D

	.is_theme_dark &
		color: rgba(#ffffff, var(--text-opacity))

	@media (min-width: common.$break62)
		line-height: 1.6
		// font-size: 1.25rem

.actions
	display: flex
	flex-wrap: nowrap
	width: 80%
	justify-content: center
	gap: 1.25rem
	padding-top: 1.25rem

.formWrapper
	display: flex
	width: 100%

.formLeftSide
	width: 45%

.formLeftSideContainer
	max-width: 290px
	display: flex
	flex-direction: column

.formLeftSideTitle
	color: #0A0A0E
	font-size: 4rem
	line-height: 1
	letter-spacing: -2.56px
	font-family: common.$objectSans
	margin: unset
	padding: unset
	margin-bottom: 1.5rem

.formLeftSideQuote
	font-size: 1.375rem
	font-family: common.$sourceSans3
	font-weight: 400
	line-height: 30px
	margin-bottom: 1.5rem

.svgContainer
	width: 100%
	height: 20px
	position: relative

.arrowShape
	position: absolute
	right: -3%

.form
	display: flex
	justify-content: center
	width: 55%

.leftWrapper
	background: #C5E7FF
	display: flex
	width: 100%
	padding: 5rem
	margin-top: 4rem
	border-radius: 3.5rem

@media (max-width: common.$break62)
	.actions
		width: auto
		flex-direction: column
		min-width: 150px
	.leftWrapper
		flex-direction: column
		padding: 2rem
		border-radius: 0rem
	.formLeftSide
		width: 100%
	.formLeftSideTitle
		font-size: 2rem
		margin-bottom: 1rem
		line-height: 40px
		letter-spacing: -0.64px
	.formLeftSideQuote
		font-size: 18px
		line-height: 26px
		margin-bottom: 2rem
	.form
		width: 100%
		max-width: 400px

	.svgContainer
		display: none

//jumbrotron arrow styles
.svgWrapper
	// position: absolute
	// max-height: 50px
	// right: 2px
	// bottom: -60px
	position: absolute
	max-height: 35px
	width: 100%
	height: 100px
	@media (min-width: common.$break62)
		position: absolute
		right: -125px
		height: auto
		width: auto
		top: 30%
		bottom: 0

.svgStyles
	// height: 75px
	// width: 66px
	height: 100%
	width: 100%
	@media (min-width: common.$break62)
		width: 115px
		height: 130px
