@use 'common'

.wrapper
	height: 100%

.category

	&List
		position: relative
		margin-top: .625rem
		z-index: 1

	&Link
		color: common.$blue

	&Name
		//
