@use 'common'

.wrapper
    margin-top: 4rem
    display: flex
    background: #0A0A0E
    border-radius: 3rem
    width: 100%
    padding: 5rem
    gap: 4rem
    @media (max-width: common.$break48)
        flex-direction: column
        gap: 2rem
        padding: 3rem

.leftSideContainer
    width: 50%
    display: flex
    flex-direction: column
    gap: 0rem
    @media (max-width: common.$break48)
        width: 100%
.title
    color: white
    font-family: common.$fustat
    font-size: 3.875rem
    font-weight: 500
    line-height: 4.5rem
    letter-spacing: -0.03rem
    max-width: 24rem
    margin-block: 0
    @media (max-width: common.$break48)
        font-size: 3rem
        line-height: 3.5rem
        letter-spacing: -0.04rem

.description
    font-size: 1rem
    font-weight: 400
    line-height: 1.5rem
    color: white
    max-width: 23rem
    @media (max-width: common.$break48)
        font-size: 0.875rem
        letter-spacing: 0.04rem

.gridContainer
    width: 50%
    display: flex
    flex-direction: column
    gap: 1rem
    @media (max-width: common.$break48)
        width: 100%

.firstRow
    width: 100%
    display: flex
    gap: 1rem

.box
    width: 100%
    background: #F5F5F5
    border-radius: 1rem
    position: relative
    display: flex
    flex-direction: column

.image
    height: 80%
    max-height: 155px
    width: 100%
    border-radius: 1rem 1rem 0rem 0rem
    object-fit: cover

.boxTitle
    margin-block: 0
    padding: 1.5rem
    font-size: 1.25rem
    font-weight: 400
    line-height: 1.75rem
    letter-spacing: -0.025rem
    color: #0A0A0E
    font-family: common.$objectSans
    @media (max-width: common.$break48)
        font-size: 1rem
        padding: 1.25rem

.secondRow
    width: 100%
    display: flex
