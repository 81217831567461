@use 'common'

=imageShift($axis: horizontal, $direction: 1, $distance: 10)
	@media (min-width: common.$break48)
		@if $axis == horizontal
			transform: translateX(calc(1px * $direction * var(--VisualLine-offset) / $distance))
		@else if $axis == vertical
			transform: translateY(calc(-1px * $direction * var(--VisualLine-offset) / $distance))
		@else
			@error "Unknown axis #{$axis}"

.images

	&.is_option1
		display: grid
		grid-template-columns: 1fr 1fr 1fr 1fr
		grid-template-rows: 1fr 1fr
		gap: .625rem

		&.is_first

			.image

				&:nth-child(1)
					+imageShift(horizontal, -1, 20)
					z-index: -1

				&:nth-child(2)
					+imageShift(vertical, -1)
					grid-column: 3

				&:nth-child(3)
					+imageShift(vertical)
					grid-column: 2

				&:nth-child(4)
					+imageShift(horizontal, 1, 50)
					grid-column: 4

				&:nth-child(3),
				&:nth-child(4)
					grid-row: 2

		&.is_second

			.image

				&:nth-child(1)
					+imageShift(horizontal, 1, 40)
					position: relative
					z-index: -1

				&:nth-child(2),
				&:nth-child(3)
					+imageShift(vertical, -1, 40)

				&:nth-child(4)
					+imageShift(horizontal, -1)
					justify-self: flex-end

				&:nth-child(5)
					+imageShift(horizontal, 1, 50)
					grid-column: 2

	&.is_option2
		&.is_first

			.image

				&:nth-child(1)
					position: absolute
					top: 15vw
					left: 30vw
					width: 27vw

					@media (min-width: common.$break48)
						top: 25%
						left: 15%
						width: 13vw
						+imageShift(horizontal, 1, 30)

				&:nth-child(2)
					position: absolute
					top: 12vw
					right: -5vw
					width: 28vw

					@media (min-width: common.$break48)
						top: 50%
						left: 8%
						width: 15vw
						+imageShift(horizontal, -1, 30)

		&.is_second

			.image

				&:nth-child(1)
					position: absolute
					top: 43vw
					left: -5vw
					width: 35vw

					@media (min-width: common.$break48)
						top: 5%
						left: 70%
						width: 15vw
						+imageShift(horizontal, -1, 30)

				&:nth-child(2)
					position: absolute
					top: 19vw
					right: 15vw
					width: 21vw

					@media (min-width: common.$break48)
						top: 28%
						left: 85%
						width: 7vw
						+imageShift(horizontal, 1, 30)

				&:nth-child(3)
					position: absolute
					top: 12vw
					left: 10vw
					width: 30vw

					@media (min-width: common.$break48)
						top: 70%
						left: 80%
						width: 12vw
						+imageShift(vertical, 1, 10)

	&.is_mobile
		@media (min-width: common.$break48)
			display: none

	&.is_desktop
		@media not all and (min-width: common.$break48)
			display: none

.image
	max-width: calc(var(--VisualLine-image-width) * 1px)
	width: 100%
	aspect-ratio: calc(var(--VisualLine-image-width) / var(--VisualLine-image-height))
	position: relative
	z-index: 0
