@use 'common'

.wrapper
	padding-bottom: 1.25rem

.list
	display: grid
	gap: 1.0625rem
	row-gap: 2.125rem

	@media (min-width: common.$break32)
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break48)
		grid-template-columns: repeat(min(3, var(--GridCardListIcon-list-maxColumnsInRow)), 1fr)

.item

	&.is_mobileHidden
		@media not all and (min-width: common.$break32)
			display: none

.title
	font-size: 2.5em
	line-height: 1.2083
	letter-spacing: common.$letter-spacing
	margin-block: unset

	@media (min-width: common.$break30)
		font-size: 3em
