@use 'common'

$-border-width: 0.0625rem
$-transition-duration: 0.3s

.wrapper
	position: relative

	&::before
		content: ''
		position: absolute
		inset: 0
		border: $-border-width solid

		@media (prefers-reduced-motion: no-preference)
			transition-propery: opacity
			transition-duration: $-transition-duration

	&.is_imageVisibile::before
		opacity: 0

.cross
	position: absolute
	inset: 0
	width: 100%
	height: 100%

	@media (prefers-reduced-motion: no-preference)
		transition-propery: opacity
		transition-duration: $-transition-duration

	.is_imageVisibile &
		opacity: 0

	line
		stroke-width: $-border-width
		stroke: currentColor

@keyframes drop
	0%
		transform: scale(0.8)
	100%
		transform: scale(1)

.image
	position: absolute
	inset: 0
	opacity: 0
	visibility: hidden
	border-radius: 7%
	overflow: hidden

	@media (prefers-reduced-motion: no-preference)
		transition-propery: opacity, visibility
		transition-duration: $-transition-duration
		animation-duration: 0.2s

	.is_imageVisibile &
		opacity: 1
		visibility: inherit
		animation-name: drop

	&_in
		object-fit: cover
