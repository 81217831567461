@use 'common'

.wrapper
	height: 100%
	// overflow: hidden
	border-radius: 56px

.list
	//

.item
	width: 100%
	height: 100%
	position: relative
	aspect-ratio: var(--AsideContentMediumList-media-width) / var(--AsideContentMediumList-media-height)
	filter: drop-shadow(common.$box-shadow)
	z-index: 0

	@media (min-width: common.$break38)
		&:not(:last-child)
			margin-bottom: 1.25rem
