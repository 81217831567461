@use 'common'

.wrapper
	position: relative
	z-index: 0

	&.is_background_black,
	&.is_background_blue
		.title
			color: #FFFFFF

.main
	padding-block: 3.75rem 5rem

	@media (min-width: common.$break48)
		padding-block: 6.25rem 9.375rem

.title
	+common.title
	margin-top: unset
	margin-bottom: 3.125rem
	text-align: center
	color: inherit

	@media (min-width: common.$break62)
		font-size: 3em

.form
	max-width: 30rem
	padding: 2.5rem 1.25rem
	margin-inline: auto
	border-radius: 1.25rem
	background-color: #FFFFFF
	color: common.$textColor

	.is_theme_dark &
		background-color: #000000
		color: #FFFFFF

	@media (min-width: common.$break30)
		padding-inline: 2.5rem

.background
	position: absolute
	inset: 0
	z-index: -1
