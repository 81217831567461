@use 'common'

.wrapper
	display: inline-block
	position: relative

.select
	position: absolute
	inset: 0
	opacity: 0
	cursor: pointer
