@use 'common'

.wrapper
	//

.tiles
	display: grid
	flex-wrap: wrap
	gap: 60px

	@media (min-width: common.$break30)
		column-gap: 40px
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break48)
		grid-template-columns: 1fr 1fr 1fr

	@media (min-width: common.$break62)
		column-gap: 60px

.tile
	//
