@use 'common'

.wrapper
	+common.form
	row-gap: .9375rem

.inputs
	+common.formInputs

.note
	+common.formNote

.view_place_card
	.button
		width: 100%
		display: flex
		flex-direction: column
