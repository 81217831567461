@use 'common'
@use 'sass:math'

.wrapper
	position: relative
	display: inline-flex
	color: inherit
	margin: 0
	padding: 0
	font-size: inherit
	border: none
	background-color: transparent
	transition-property: color
	transition-duration: 0.2s

	&::before
		content: ''
		position: absolute
		inset: -0.5rem

	&:hover
		color: #FFFFFF
