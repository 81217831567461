@use 'common'

.wrapper
	//

.list
	display: flex
	flex-direction: column
	gap: 1rem

.item
