@use 'common'

.wrapper
	//

.portal
	//

.is_modalOpen
	//

.overlay
	display: flex
	justify-content: center
	align-items: center
	position: fixed
	inset: 0
	background-color: rgba(0, 0, 0, 0.3)
	padding: var(--global-horizontal-spacing)
	z-index: 10
	opacity: 0
	visibility: hidden
	transition-property: visibility, opacity
	transition-duration: .3s

	&.is_afterOpen
		opacity: 1
		visibility: visible
		z-index: 9999

	&.is_beforeClose
		opacity: 0
		visibility: hidden

	@media (min-width: common.$break32)
		padding: 3.125rem

.main
	display: flex
	justify-content: center
	align-items: center

.content

	&.is_video
		max-width: common.$contentWidth-normal
		width: 100%

	&.is_video &
		&In
			aspect-ratio: var(--Modal-videoMobile-aspectRatio, 4 / 3)
			position: relative
			width: 100%
			height: auto
			margin: auto
			background-color: #FFFFFF

			@media (min-width: common.$break32)
				aspect-ratio: var(--Modal-video-aspectRatio, 16 / 9)
