@use 'common'
@use 'sass:math'

.wrapper
	display: flex
	flex-direction: column

	&.is_default
		height: 100%
		position: relative
		z-index: 0

	&.is_resourceBox

.link
	position: absolute
	inset: 0
	z-index: 0

	& ~ .image
		z-index: -1

	&:hover ~ .image,
	&:focus-visible ~ .image
		.imageIn
			transform: scale(1.05)

.main
	display: flex
	flex-direction: column
	width: 80vw
	max-width: 22.5rem
	height: 100%

	@media (min-width: common.$break30)
		width: 100%

	&Content
		height: 100%
		display: flex
		flex-direction: column
		color: #000000
		padding: var(--CardTileContent-mainContent-paddingBlock) 1.25rem

		&.isMainContentCentered
			justify-content: center

		@media (min-width: common.$break30)
			padding-inline: 2.5rem
			padding-bottom: 1.875rem

.image
	position: relative
	width: 100%
	flex-shrink: 0
	aspect-ratio: 4 / 3
	overflow: hidden
	z-index: 0
	background-color: #D9D9D9

	&In
		position: absolute
		inset: 0
		width: 100%
		height: 100%
		transition: transform .6s
		z-index: 0

	&.is_mobile
		@media (min-width: common.$break48)
			display: none

.title
	margin: 0
	font-size: 1.375em
	line-height: 1.23

	&.is_withBackground
		padding: 15px
		background-color: #F1F2FF
		font-size: 1.875em
		text-align: center
		border-radius: common.$border-radius

	@media (min-width: common.$break30)
		font-size: 1.625em

.subtitle
	&.is_aboveTitle
		order: -1

.text
	color: inherit
	margin-top: var(--CardTileContent-text-topSpacing)
	// word-break: break-word
	// white-space: pre-line

.footer
	margin-top: auto
