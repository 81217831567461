@use 'common'

$boxPadding: 1.875rem

.wrapper
	//

.items
	display: grid
	gap: 2.1875rem
	align-items: center

	@media (min-width: 700px)
		grid-template-columns: 1fr 1fr

.item
	height: 100%

	&.is_logoList
		grid-column: 1 / -1

		.text
			max-width: 31.25rem

.box
	position: relative
	display: flex
	flex-direction: column
	height: 100%
	min-height: 18.75rem
	align-items: center
	border-radius: 1.875rem
	overflow: hidden
	z-index: 0
	padding: $boxPadding

	&.is_onlyTitle
		justify-content: center

	&.is_default
		background-color: #EAEAFF

	&.is_dark
		background-color: #1B1A2F
		color: #FFFFFF

		.title
			color: inherit

	&.is_dark, &.is_default
		.title
			text-align: center

		.text
			color: inherit
			text-align: center

	@media (min-width: common.$break22)
		padding: 3.125rem

.backgroundImage
	z-index: -1

	&In
		object-fit: cover

	&::after
		content: ''
		position: absolute
		inset: 0
		background: radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(64, 64, 64) 90.2%)
		opacity: .3
		z-index: 0

.title
	font-size: 1.5em
	line-height: 1.1
	margin-block: unset

	&.is_bigger
		line-height: 1.1
		font-size: 1.875rem

		@media (min-width: common.$break22)
			font-size: 2.375rem

		@media (min-width: common.$break62)
			font-size: 2.5rem

.text
	margin: unset
	margin-block: 1rem 0

.image
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100%
	margin-top: 1.5625rem

	&.is_desktop
		@media not all and (min-width: common.$break30)
			display: none

	&.is_mobile
		@media (min-width: common.$break30)
			display: none

	&In
		max-width: 100%
		height: auto

	@media (min-width: common.$break30)
		margin-top: 3.125rem

.logoList
	$logoListBreak: common.$break38
	width: 100%
	margin-top: 2.5rem

	@media not all and (min-width: $logoListBreak)
		.is_desktop
			display: none

	@media (min-width: common.$break30)
		margin-bottom: 2.5rem - $boxPadding

	@media (min-width: $logoListBreak)
		.is_mobile
			display: none

	@media (min-width: common.$break62)
		margin-top: auto
