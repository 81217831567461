@use 'common'

.title
    +common.title
    text-align: center
    font-family: common.$objectSans

    letter-spacing: -0.18rem

    @media (min-width: common.$break62)
        line-height: 5rem

    & b
        font-family: 'caprasimo'
        font-weight: 400

.text
    max-width: 52.625rem
    text-align: center
    margin: 2rem 0
    color: #4D4D4D
    font-family: common.$sourceSans3
    font-size: 1rem
    font-style: normal
    font-weight: 400
    line-height: 1.625rem

    margin: auto

    .is_theme_dark &
        color: rgba(#ffffff, var(--text-opacity))

    @media (min-width: common.$break62)
        font-size: 1.125rem
        line-height: 1.625rem

.btnWrapper
    display: flex
    gap: 1rem
    padding-top: 2.5rem
    justify-content: center
    align-items: center

.ratingsWrapper
    display: flex
    justify-content: center
    align-items: center
    margin: auto
    padding-top: 4rem

    @media (max-width: common.$break32)
        padding-top: 3rem
