@use 'common'

.wrapper
	//

.list
	display: grid
	gap: 1.25rem
	margin-top: 3.4375rem

	@media (min-width: common.$break30)
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break48)
		grid-template-columns: 1fr 1fr 1fr

.item
	display: flex
	flex-direction: column
