@use 'common'
@use 'sass:math'

$-break: common.$break75
$-color-bland: #606064

.wrapper
	display: grid
	grid-auto-flow: dense
	grid-template-rows: min-content
	min-height: 100%
	row-gap: 2em

	@media (min-width: $-break)
		grid-template-rows: none
		grid-template-columns: 1fr auto

.sidebar
	padding: 1.8em var(--global-horizontal-spacing) 0

	@media (min-width: $-break)
		grid-column: 2
		width: 21rem

.frame
	width: 100%
	height: 100%
	min-height: 16rem
	position: relative

	&.is_forceAspectRatio
		aspect-ratio: var(--InspirationDetail-aspectRatio)
		height: auto

.close
	text-align: right
	padding-bottom: 2.75em
	color: #9B9B9B

.header
	//

.title
	font-size: 1.25em
	font-weight: 500
	margin: 0

.lead
	margin-top: 0.8em
	color: $-color-bland
	font-size: 0.875em
	font-weight: 500

.emulation
	margin-top: 1.25em
	display: flex

	&Option
		display: flex
		align-items: center
		justify-content: center
		border: 1px solid transparent
		margin: 0
		padding: 0.5em 0.4em
		font-size: 1.1875em
		color: inherit
		color: #888C9A
		background-color: transparent
		border-radius: 0.5em
		min-width: 2.1em

		&.is_active
			border-color: #888C9A
			color: #FFFFFF

.stats,
.more
	position: relative

	&::before
		content: ''
		position: absolute
		top: 0
		left: calc(-1 * var(--global-horizontal-spacing))
		right: calc(-1 * var(--global-horizontal-spacing))
		border-top: 1px solid $-color-bland

.stats
	display: flex
	gap: 1.5em
	margin-top: 2em
	padding: 1.25em 0

.views,
.likes
	display: flex
	gap: 0.5em
	border: none
	background-color: transparent
	padding: 0
	margin: 0
	color: #9B9B9B
	font-size: inherit
	font-family: inherit
	align-items: center

	&:is(button):not(:disabled)
		&:hover
			color: #FFFFFF

.icon
	position: relative
	display: flex

.views .icon
	top: 0.1em

.likes .icon
	top: 0.05em

.more
	color: $-color-bland

.tags
	padding-top: 1.25em
	display: flex
	flex-wrap: wrap
	gap: 0.6875em

.tag
	color: #9B9B9B
	font-size: 0.875em
	font-weight: 500
	border: 1px solid #494A53
	border-radius: 0.7em
	padding: 0.3em 0.7em

.description
	font-size: 0.875em
	font-weight: 500
	padding-top: 2.2em

.content
	display: grid

	&In
		display: contents
		position: relative

	&.is_emulateMobile &In
		margin: 2em auto 0
		display: grid
		aspect-ratio: 804 / 1580
		max-width: 100%
		max-height: 80vh

		.frame
			$top: 11%
			$bottom: 13%
			$left: 5%
			$right: 5.2%

			position: absolute
			top: $top
			bottom: $bottom
			left: $left
			right: $right
			width: 100% - $left - $right
			height: 100% - $top - $bottom
			min-height: 0
