@use 'common'

$-horizontal-padding: 1.25rem

.wrapper
	position: relative
	z-index: 0
	display: grid
	height: 37.5rem
	background-color: #ffffff
	grid-template-rows: auto 1fr auto
	border-radius: 0.125rem
	overflow: hidden
	border: 0.0625rem solid rgba(#B6B6B6, 0.5)
	box-shadow: 0px 0.125rem 0.875rem rgba(#000000, 0.15)

.header
	position: relative
	padding: 0.625rem $-horizontal-padding
	color: #333333
	font-weight: 600
	display: grid
	align-items: center
	grid-template-columns: auto 1fr auto
	column-gap: 0.5em

	&::before
		content: ''
		position: absolute
		inset: auto 0 0
		border-bottom: 0.0625rem solid #e5e5e5

.logo
	display: grid
	position: relative
	top: -0.07em

.close
	position: relative
	aspect-ratio: 1 / 1
	width: 1em

	&::before,
	&::after
		content: ''
		position: absolute
		inset: 50% 0 auto
		background-color: currentColor
		height: 0.0625rem
		transform: translateY(-50%) rotate(45deg)

	&::after
		transform: translateY(-50%) rotate(-45deg)

.title
	position: absolute
	z-index: 1
	inset: 100% 0 auto
	padding: 0.875rem calc(#{$-horizontal-padding} + 5%)
	background-color: rgba(#ffffff, 0.9)
	color: #000000
	font-size: 1rem
	display: grid
	align-items: center
	column-gap: 0.5em
	grid-template-columns: 1fr auto 1fr
	backdrop-filter: blur(0.25rem)
	font-weight: 500

.back
	position: relative
	aspect-ratio: 1 / 2
	width: 0.5em

	&::before,
	&::after
		content: ''
		position: absolute
		inset: 50% 0 auto
		background-color: currentColor
		height: 0.0625rem
		transform-origin: 0 50%
		transform: translateY(-50%) rotate(45deg)

	&::after
		transform: translateY(-50%) rotate(-45deg)

.messages
	position: relative
	overflow: hidden
	display: grid

	&_in
		position: absolute
		inset: auto 0 0
		padding-inline: $-horizontal-padding
		display: grid
		padding-bottom: 1rem

.footer
	$border-radius: 1rem
	background-color: #f6f5ff
	border-top-left-radius: $border-radius
	border-top-right-radius: $border-radius
	padding: 1.25rem $-horizontal-padding
	box-shadow: 0px -0.125rem 1rem rgba(#000000, 0.08)

.input
	background-color: #ffffff
	border: 0.0625rem solid common.$blue
	border-radius: 0.375rem
	padding: 0.5rem 0.3125rem
	font-size: 0.75rem
	display: flex

.text
	color: #000000

	&::after
		position: relative
		top: -0.1em
		content: '|'
		animation: blink 1s step-end infinite

		@keyframes blink
			50%
				opacity: 0

.placeholder
	color: #979a9b

	.text:not(:empty) ~ &
		display: none
