@use 'common'

.wrapper
	//

.main
	display: flex
	flex-direction: column
	gap: 1.25rem

	@media (min-width: common.$break38)
		flex-direction: row
		align-items: center
		gap: 1.5625rem

.image
	position: relative
	width: 3.125em
	aspect-ratio: 1 / 1
	border-radius: 50%
	overflow: hidden

.name, .companyName
	display: block
	font-family: common.$sourceSans3Stack
	font-size: 1.125rem
	line-height: 1.625rem
	font-style: normal
	font-weight: 500
	// line-height: 1.625rem

	@media (min-width: common.$break30)
		display: initial

.companyName
	font-weight: 400 !important

	@media (min-width: common.$break30)
		&::before
			content: ''
