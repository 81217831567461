@use 'common'

.wrapper
	//
	background-color: #F5F5F5

	border-radius: 2rem
	width: 100%

.main

	--AsideContentAccordionItem-block-spacing: 1.25rem
	--AsideContentAccordionItem-inline-spacing: 1.275rem

	@media (min-width: common.$break48)
		--AsideContentAccordionItem-block-spacing: 1.875rem
		--AsideContentAccordionItem-inline-spacing: 3.125rem

.header
	//

.button
	width: 100%
	font-style: common.$objectSansStack
	font-size: 1.25rem
	line-height: 1.75rem
	letter-spacing: -0.025rem
	display: flex
	justify-content: space-between
	align-items: center
	gap: 1.25rem
	background: unset
	border: unset
	padding: 1.5rem
	font-family: inherit
	font-weight: 400
	text-align: left
	color: inherit

	&.resetPadding
		padding: var(--AsideContentAccordionItem-block-spacing) var(--AsideContentAccordionItem-inline-spacing) 0 var(--AsideContentAccordionItem-inline-spacing)

	@media (min-width: common.$break48)
		font-size: 1.5rem
		line-height: 2rem
		letter-spacing: -0.03rem
		padding: 1.875rem 3.125rem 1.875rem 3.125rem

.mark
	//
	color: #0A0A0E
	font-size: 1.875rem

.titleWrapper
	display: flex
	align-items: center
	gap: 8px

	&:has(.emojiWrapper)
		font-family: common.$objectSans

.body
	padding: 0rem 1.5rem 1.5rem 1.5rem
	font-style: common.$sourceSans3Stack
	font-size: 1rem
	line-height: 1.625rem
	display: flex

	&.resetPadding
		padding: 1.5rem var(--AsideContentAccordionItem-inline-spacing) var(--AsideContentAccordionItem-block-spacing) var(--AsideContentAccordionItem-inline-spacing)

	@media (min-width: common.$break48)
		font-size: 1.125rem
		line-height: 1.625rem
		padding: 0 3.125rem 1.875rem 4.25rem

.emojiWrapper
	position: relative
	width: 60px
	height: 60px
	display: flex
	align-items: center
	justify-content: center
	background: white
	border-radius: 100%

	@media (min-width: common.$break48)
		width: 80px
		height: 80px
		display: flex
		align-items: center
		justify-content: center
		background: white
		border-radius: 100%

.emojiStyles
	object-fit: contain
	height: 40px
	width: 40px
	@media (min-width: common.$break48)
		object-fit: contain
		width: 60px
		height: 60px

.invisibleDiv
	width: 10px
	@media (min-width: common.$break48)
		width: 90px
