=form
	display: flex
	flex-direction: column
	flex-grow: 1

=formInputs
	flex-grow: 1
	display: flex
	flex-direction: column
	row-gap: .9375rem

=formNote
	margin-top: 1.875rem
	font-size: .875rem
	max-width: 17.0625rem
