@use 'common'

.wrapper
	//

.form
	display: flex
	flex-direction: column
	align-items: center
	gap: .625rem

	@media (min-width: common.$break30)
		flex-direction: row

.submittedIcon
	font-size: 2.75rem
	margin-bottom: .9375rem

.button
	flex-shrink: 0

.modal
	display: flex
	justify-content: center
	align-items: center
	pointer-events: none

	&Content
		position: relative
		max-width: 26.25rem
		width: 100%
		pointer-events: all
		padding: 2.8125rem
		border: 1px solid #3E3E3E
		border-radius: .9375rem
		background-color: #000000
		color: #FFFFFF
		z-index: 0

	&Title
		text-align: center
		font-size: 1.625em
		line-height: 1.1
		letter-spacing: common.$letter-spacing
		margin-block: 0 .75rem
		color: #FFFFFF

	&CloseButton
		border: none
		cursor: pointer
		pointer-events: all
		color: #FFFFFF
		background-color: transparent

		position: absolute
		display: flex
		top: 1.25rem
		right: .9375rem
		font-size: 1.25rem
		padding: .625rem
