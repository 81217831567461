@use 'common'
@use 'sass:math'

.background
	display: flex
	padding-bottom: 3.5rem

	@media (min-width: common.$break48)
		padding-bottom: 0

	&.is_background_white, &.is_background_grey
		.title
			color: #000000

	&.is_background_blue, &.is_background_black
		.title
			color: #FFFFFF

.wrapper
	display: flex
	flex-direction: column
	align-items: center
	max-width: 60rem
	margin: auto

	&.is_about_page
		max-width: 100%

.title
	font-family: common.$objectSansStack
	font-weight: 400
	font-size: 2rem
	line-height: 2.5rem
	letter-spacing: -0.04rem
	text-align: center
	margin: 0
	color: inherit
	@media (min-width: common.$break38)
		font-size: 3rem
	@media (min-width: common.$break62)
		font-size: 4rem
		line-height: 4.375rem
		letter-spacing: -0.16rem
		margin-top: 1rem

.subtitle
	margin: 1rem
	text-align: center
	max-width: 48rem

.bannerFeatures
	display: flex
	flex-wrap: wrap
	justify-content: center
	width: 100%
	@media (min-width: common.$break48)
		// margin: 1rem 0 0
	@media (min-width: common.$break75)
		// margin: 4rem 0
		padding-top: 5rem

.bannerFeature
	display: flex
	flex-direction: column
	align-items: center
	width: 100%
	text-align: center
	margin-top: 2rem

	width: math.div(100%, 2)

	&.is_about_page
		width: math.div(100%, 1)

	@media (min-width: common.$break30)
		// width: math.div(100%, 2)
		&.is_about_page
		// width: math.div(100%, 1)

	@media (min-width: common.$break48)
		padding-bottom: 5rem

		width: math.div(100%, 3)

		&.is_about_page
			width: math.div(100%, 3)

.checkmark
	font-size: 2em

.bannerFeatureMainTitle
	margin-top: 1.18rem
	margin-bottom: -0.125rem
	font-size: 1.25rem
	line-height: 1.75rem
	letter-spacing: -0.025rem

	@media (min-width: common.$break48)
		margin-top: 2.5rem
		margin-bottom: -0.125rem
		font-size: 2rem
		line-height: 2.5rem
		letter-spacing: -0.04rem

.bannerFeatureTitle
	max-width: 65%
	font-family: common.$sourceSans3Stack
	font-size: 1rem
	font-weight: 400
	letter-spacing: -0.03rem

	&.is_about_page
		font-size: 1rem
		max-width: 80%

		@media (min-width: common.$break48)
			font-size: 1.125rem
