@use 'common'

.wrapper

    @media (min-width: common.$break62)
        padding-top: 5rem

    .view_mobile
        @media (min-width: common.$break30)
            display: none

    .view_desktop

        @media not all and (min-width: common.$break30)
            display: none
