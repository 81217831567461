@use 'common'
@use 'sass:math'

$-width: 11em
$-background-color: #FFFFFF

.wrapper
	position: relative
	color-scheme: light

.label
	display: flex
	justify-content: center
	align-items: center
	gap: 0.3em
	margin: 0
	padding: 0.625em math.div($-width, 4)
	width: 100%
	border: none
	background-color: transparent
	color: #9B9B9B
	font-size: 0.875em
	font-weight: 500
	transition-property: color
	transition-duration: 0.3s // @TODO: handle prefers-reduced-motion

	.is_open &
		color: #FFFFFF

	&::after
		$size: 0.25em

		content: ''
		border-top: solid 1.5 * $size
		border-left: solid $size transparent
		border-right: solid $size transparent

		.is_open &
			transform: scaleY(-1)

.list
	list-style-type: none
	margin: 0
	padding: 0

.in
	position: absolute
	left: calc(50% - math.div($-width, 2))
	top: calc(100% + 0.625em)
	z-index: 1
	width: $-width
	opacity: 0
	visibility: hidden
	transform: translateY(1em)
	transition-property: opacity, visibility, transform
	transition-duration: 0.3s // @TODO: handle prefers-reduced-motion

	.is_open &
		opacity: 1
		visibility: inherit
		transform: none

	&::before
		$size: 0.625em

		position: absolute
		bottom: 100%
		left: calc(50% - #{$size})
		content: ''
		border-bottom: $size solid $-background-color
		border-left: $size solid transparent
		border-right: $size solid transparent

.content
	background-color: $-background-color
	color: #333333
	box-shadow: inset 0px -1px 0px #E6E6E6
	border-radius: 0.5em
	overflow: hidden

	&In
		max-height: 25em
		padding-right: 0.5em
		margin: 0.25em 0.25em 0.25em 0
		overflow-y: auto

		&::-webkit-scrollbar
			width: 0.25em

		&::-webkit-scrollbar-track
			background-color: #DEDEDE

		&::-webkit-scrollbar-thumb
			background-color: #1B1B26
			border-radius: 0.125em

.item
	font-weight: 500

	&:not(:last-child)
		border-bottom: 1px solid #E6E6E6

	&.is_active
		font-weight: 700

.itemLink
	display: flex
	align-items: center
	padding: 0.5em 1em
	min-height: 3em
	font-size: 0.8125em
