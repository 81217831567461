@use 'common'

.wrapper
    display: flex
    flex-direction: column
    justify-content: center
    gap: 1rem
    @media (min-width: common.$break32)
        flex-direction: row

.cardContainer
    width: 100%
    border-radius: 2rem
    background-color: #FAFAFA

.firstCard
    width: 100%
    @media (min-width: common.$break62)
        max-width: 404px

.secondCard
    width: 100%
    @media (min-width: common.$break32)
        max-width: 404px

.imageContainer
    height: 256px
    max-height: 50%
    width: auto
    position: relative
    background-color: #C5E7FF
    border-top-right-radius: 2rem
    border-top-left-radius: 2rem

.image
    border-top-right-radius: 2rem
    border-top-left-radius: 2rem
    object-fit: cover

.infoContainer
    display: flex
    flex-direction: column
    gap: 2rem
    padding: 2.5rem 1.96rem
    @media (min-width: common.$break32)
        padding: 3rem 40px
        min-height: 15rem

.postTitle
    margin-block: 0
    font-weight: 400
    font-size: 1.25rem
    line-height: 1.75rem
    letter-spacing: -0.25px
    color: #0A0A0E
    font-family: common.$objectSansStack
    @media (min-width: common.$break32)
        font-size: 1.5rem
        line-height: 2rem
        letter-spacing: -0.03px

.link
    color: #1B00FB
    font-size: 1.125rem
    text-decoration: underline
    line-height: 1.5rem
    @media (min-width: common.$break32)
        font-size: 1.375rem
        line-height: 1.875rem

.backLinkContainer
    display: flex
    justify-content: center
    align-items: center
    margin-top: 5rem
    @media (min-width: common.$break32)
        margin-top: 8.13rem

.backLink
    background-color: #1B00FB
    font-size: 1.125rem
    font-weight: 500
    line-height: 1.625rem
    color: #fff
    padding: 1rem 0rem
    margin-block: 0
    border-radius: 0.625rem
    width: 100%
    text-align: center
    @media (min-width: common.$break32)
        width: auto
        padding: 1rem 6rem
