@use 'common'

.wrapper
	display: flex
	flex-direction: column

a:hover, a:focus-visible
	& ~ .background
		transform: scale(1.01)

		&::before
			opacity: .1

.box
	display: flex
	justify-content: flex-end
	position: relative
	border-radius: 1.25rem
	z-index: 0

	&::before
		content: ''
		display: block
		padding-top: calc(9 / 16 * 100%)

	@media (min-width: common.$break48)
		padding: 2.5rem
		overflow: hidden

.card
	height: 100%

	@media (min-width: common.$break48)
		max-width: 19.6875rem

.button
	display: flex
	flex-direction: column
	margin-top: 5rem

.background
	position: absolute
	inset: 0
	background-color: #D3D3D3
	z-index: -1
	transition: transform .6s

	&::before
		content: ''
		position: absolute
		inset: 0
		z-index: 1
		opacity: 0
		background-color: #000000
		transition: opacity .2s

	@media not all and (min-width: common.$break48)
		display: none
