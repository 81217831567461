@use 'common'

.plansWrapper
    width: 100%
    display: flex
    flex-direction: column
    gap: 1.07rem

    @media (min-width: common.$break62)
        flex-direction: row

.cardContainer
    width: 100%
    display: flex
    padding: 3.5rem 1.5rem
    border-radius: 2rem
    @media (min-width: common.$break38)
        padding: 3.5rem 2.5rem

.contentWrapper
    display: flex
    flex-direction: column

.mainTitle
    font-family: common.$objectSansStack
    font-size: 3.125rem
    line-height: 3.5rem
    letter-spacing: -0.125rem
    margin-block: 0
    margin-bottom: 0.750rem
    color: #000

.title
    font-size: 1rem
    line-height: 1.5rem
    margin-block: 0
    margin-bottom: 1.5rem
    color: #000
    @media (min-width: common.$break38)
        font-size: 1.125rem

.divider
    height: 0.0625rem,
    width: 100%
    margin-bottom: 1.5rem
    @media (min-width: common.$break38)
        max-width: 60%
    @media (min-width: common.$break62)
        max-width: 100%

.listItems
    display: flex
    gap: 0.5rem
    font-size: 1rem
    line-height: 1.5rem
    font-weight: 500
    color: #000

.checkmark
    width: 0.875rem
    height: 0.63206rem

.buttonWrapper
    margin: 2rem 0rem
    @media (min-width: common.$break38)
        max-width: 60%
    @media (min-width: common.$break62)
        max-width: 100%

.description
    margin-block: 0
    color: #000
    font-size: 1rem
    line-height: 1.5rem
    @media (min-width: common.$break38)
        max-width: 60%
    @media (min-width: common.$break62)
        max-width: 100%
