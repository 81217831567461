@use 'common'
@use 'sass:math'

.link
	display: flex

	&:hover, &:focus-visible
		.image
			&In
				transform: scale(1.05)

.main
	display: flex
	flex-direction: column
	width: 80vw
	max-width: 22.5rem
	height: 100%

	@media (min-width: common.$break30)
		width: 100%

	&Content
		height: 100%
		display: flex
		flex-direction: column
		color: #000000
		padding: 1.875rem 1.25rem 1.25rem

		@media (min-width: common.$break30)
			padding: 3.125rem 2.5rem 1.875rem

.image
	position: relative
	width: 100%
	flex-shrink: 0
	aspect-ratio: 4 / 3
	overflow: hidden

	&In
		position: absolute
		inset: 0
		width: 100%
		height: 100%
		transition: transform .6s

.title
	margin: 0
	font-size: 1.375em
	line-height: 1.23

	@media (min-width: common.$break30)
		font-size: 1.625em

.text
	margin-top: 1.25rem

.footer
	margin-top: auto
