@use 'common'

.wrapper
	aspect-ratio: var(--Frame-iframe-aspectRatioMobile)
	position: relative
	width: 100%

	@media (min-width: common.$break62)
		aspect-ratio: var(--Frame-iframe-aspectRatio)

.iframe
	position: absolute
	inset: 0
	margin: 0
	padding: 0
	border: 0 none
	width: 100%
	height: 100%
	min-height: 100%
	min-width: 100%
