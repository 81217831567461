@use 'common'

// Table Styles

.comparison_table
  table-layout: fixed
  width: 100%
  border-collapse: separate
  border-spacing: 0
  border-radius: 2rem
  overflow-x: hidden
  border: 1px solid #f5f5f5
  margin-bottom: 10rem

  th
    padding: 1.5rem 1.5rem

    th
      color: white
      background-color: #907EFF

      &.is_popOut
        &::before
          content: ""
          position: absolute
          top: 0
          right: 0
          bottom: 0
          left: 0
          background-color: #003358
          max-height: 50%
          transform: translateY(-35px)
          border-top-left-radius: 2rem
          border-top-right-radius: 2rem

      &:not(:last-child)
        border-right: 1px solid #f5f5f5

      &:nth-child(2)
        position: relative
        background: #003358
        border-left: 1px solid #f5f5f5
        border-right: 1px solid #f5f5f5

      &:first-child
        border-top-left-radius: 2rem
      &:last-child
        border-top-right-radius: 2rem

    td
      margin: auto
      border-bottom: 1px solid #f5f5f5
      position: relative

      font-size: 1.5rem

      &:first-child
        font-size: inherit
        padding: 1.8rem 2.5rem

      &.is_popOut
        &::after
          content: ""
          position: absolute
          top: 0
          right: 0
          bottom: 0
          left: 0
          background-color: #003358
          max-height: 50%
          transform: translateY(83.5px)
          border-bottom-left-radius: 2rem
          border-bottom-right-radius: 2rem

      &:not(:last-child)
        border-right: 1px solid #f5f5f5

      &:nth-child(2)
        background: #003358
        border-left: 1px solid #f5f5f5
        border-right: 1px solid #f5f5f5
        border-bottom: 0

      &:not(:first-child)
        text-align: center

    tbody
      tr
        &:last-child
          td
            &:first-child
              border-bottom-left-radius: 2rem
            &:last-child
              border-bottom-right-radius: 2rem
.title
  font-family: common.$sourceSans3Stack
  font-size: 1.125rem
  font-style: normal
  font-weight: 500
  line-height: 1.625rem

.data_title
  font-family: common.$sourceSans3Stack
  font-size: 1.125rem
  font-style: normal
  font-weight: 400
  line-height: 1.625rem

// End of Table Styles

// Carousel Styles

.carousel_wrapper
  //

.wrapper
  width: 100%
  display: flex

  h6
    font-family: common.$objectSansStack
    font-size: 1.25rem
    font-style: normal
    font-weight: 400
    line-height: 1.75rem
    letter-spacing: -0.025rem
    margin: 0 !important

  .main
    width: inherit
    display: flex
    justify-content: center
    flex-direction: column
    padding: 2.5rem 1.5rem
    border-radius: 1.5rem
    gap: 1.875em
    background-color: #F5F5F5
    color: #000000

    &.is_Gemma
      color: #ffffff
      background: #003358

  .content_wrapper
    display: flex
    flex-direction: column

  .content
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 1rem

    span
      display: flex
      align-items: center
      justify-content: center
      font-size: 1.5rem

    p
      font-size: 1rem

// End of Carousel Styles
